import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { SiderMenu } from './SiderMenu';
import { Title } from './Title';
import { ProfileEntrance } from './ProfileEntrance';

export const DesktopMenuDrawer: React.FC = () => {    
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("desktopMenu")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("desktopMenu"));                                                                   
    }, [isMobile]);

    return (
        <div style={styles}>            
            <Title styles={styles.title} />
            <SiderMenu styles={styles.menu} />
            <ProfileEntrance buttonInColumn={true} />            
        </div>
    );
}