import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../../models/Option';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { Button } from '../Button';
import { merge } from 'react-merge';
import { getLanguageCode } from '../../../helpers/Common';

interface Props { 
    styles?: any;         
    title: string;      
    options: Option[];    
    getSelected(selectedOptions: Option[]): void;    
    closeDialog(): void;    
};

export const MultySelectDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [options, setOptions] = useState<Option[]>(props.options);             
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });   
    const languageCode = getLanguageCode();        

    useEffect(() => {   
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);                                                                  
    }, [isMobile]);

    const itemSelected = (index: number, checked: boolean) => {
        let tempOptions = [...options];        
        tempOptions[index].Checked = checked;   
        setOptions(tempOptions);          
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>
                <div style={styles.title}>{props.title}</div>
                <div style={styles.container} className={"selector"}>
                    {options.map((option, index) =>                                     
                        <div key={index} style={styles.optionWrapper} onClick={() => itemSelected(index, !option.Checked)}>                                                    
                            <input type={"checkbox"} checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                            <div style={styles.option}>
                                {option.PrimaryOption}    
                                {languageCode !== "ENG" && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </div>
                        </div>                    
                    )}    
                </div>
                <div style={styles.buttonWrapper}>
                    <Button style={styles.button} onClick={() => props.closeDialog()}>Cancel</Button>
                    <Button style={{...styles.button, ...styles.button.select}} onClick={() => props.getSelected(options)}>Select</Button>                    
                </div>
            </div> 
        </>       
    );
}