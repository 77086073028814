import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

interface Props {
    videoUrl: string;
}

const VideoPlayer: React.FC<Props> = ({ videoUrl }) => {
    const videoNode = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<any>();

    useEffect(() => {
        if (videoNode.current) {            
            playerRef.current = videojs(videoNode.current, {
                controls: true,
                autoplay: false,
                fluid: true,
                preload: 'auto',
                controlBar: {
                    children: [
                        'playToggle',
                        'currentTimeDisplay',
                        'progressControl',
                        'durationDisplay',
                        'volumePanel',
                        'fullscreenToggle'                        
                    ]
                }
            });            
            playerRef.current.src({ src: videoUrl, type: 'video/mp4' });
        }

        return () => {
            // Cleanup Video.js player on component unmount
            if (playerRef.current) {
                playerRef.current.dispose();
            }
        };
    }, [videoUrl]);

    // Disable right-click to prevent downloading
    const handleContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    return (                    
        <div data-vjs-player>
            <video
                ref={videoNode}
                className="video-js vjs-default-skin"
                onContextMenu={handleContextMenu}
            />
        </div>        
    );
};

export default VideoPlayer;
