import { Color } from "../enums/Color";
import { Font } from "../enums/Font";
import { pageLayout } from "./PageLayout";

export const onlineClassPage = {
    mobile: {    
        ...pageLayout.mobile,
        listCount: {
            fontSize: "4vw",
            color: Color.Green,            
            fontWeight: "bold",
            marginBottom: "10px"
        },        
        filter: {
            position: "relative",
            margin: "70px 0 0 0",                       
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw"            
        },
        filterExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            lineHeight: "10.86vw",           
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",            
            border: "none",
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            fontWeight: "bold",
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0"
            }
        },        
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        },
        filterContent: {
            padding: "0 4.83vw 4.83vw 4.83vw"
        },
        classButton: {
            display: "flex",
            backgroundColor: Color.Black,
            color: Color.White,
            padding: "8px 16px",
            margin: "70px 0 -50px auto",
            fontWeight: "bold" as "bold",
            fontSize: "14px",
            borderRadius: "1.41vw",
            noMargin: {
                margin: "70px 0 0 auto"
            }
        },                                
        class: {
            container: {
                margin: "20px 0 50px 0"
            },
            subContainer: {
                margin: "70px 0 50px 0"
            },
            card: {
                display: "flex",
                flexDirection: "row",
                borderBottom: "2px solid " + Color.Green,                        
                padding: "10px 0",            
                alignItems: "center",
                cursor: "pointer"
            },
            contentWrapper: {
                display: "flex",
                flexDirection: "row"
            },
            imageWrapper: {
                width: "28.98vw"
            },
            image: {
                height: "28.98vw",
                width: "28.98vw",
                borderRadius: "0.31vw",                
                objectFit: "cover",
                cursor: "pointer"
            },
            detailWrapper: {
                width: "59.09vw",
                marginLeft: "1.93vw"
            },
            name: {
                fontSize: "3.86vw",
                fontWeight: "bold"              
            },
            subjects: {
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray,
                marginTop: "1.21vw"
            },
            grades: {
                fontSize: "2.66vw",
                fontWeight: "bold",
                color: Color.DarkLiver,
                marginTop: "1.21vw"
            },
            see: {
                fontSize: "13px",
                fontWeight: "bold",
                color: Color.Green,
                marginTop: "2.41vw"
            },
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "3.38vw" 
            },
            editButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.DeepBlack,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },
            viewButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.AliceBlue,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Blue
                }
            },
            deleteButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Linen,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Red
                }
            },
            weekButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28.98vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Nyanza,
                color: Color.MediumGreen,
                fontWeight: "700",
                marginLeft: "auto",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.MediumGreen,
                    marginRight: "1.93vw"
                }
            }
        },        
        week: {
            container: {
                margin: "70px 0 50px 0"
            },
            card: {
                borderRadius: "1.21vw",            
                padding: "2.41vw 3.62vw",
                marginBottom: "2.41vw",
                background: Color.Nyanza,
                border: "1px solid " + Color.MediumGreen
            },
            expired: {
                background: Color.LightAsh,
                border: "none"
            },
            weekNo: {
                fontSize: "2.41vw",
                fontWeight: "bold",
                color: Color.DarkLiver
            },
            title: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Black
            },
            dateWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "1.54vw",
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray
            },
            rowWrapper: {
                display: "flex",
                alignItems: "center",
                width: "45%"
            },
            calendarIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw"
            },
            clockIcon: {
                height: "3.8vw",
                width: "3.38vw",
                fill: Color.DeepAsh,
                marginRight: "1.87vw",
                marginLeft: "10.14vw"
            },
            studentCount: {
                fontWeight: "bold",
                color: Color.Black,
                marginRight: "1.21vw"
            },  
            cashIcon: {
                height: "4.94vw",
                width: "auto",
                marginRight: "1.87vw",
                marginLeft: "10.14vw"
            },  
            whatsappButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                backgroundColor: "transparent",
                color: Color.PhilippineGray,
                fontSize: "3.38vw",
                fontWeight: "bold",
                marginTop: "3.38vw",
                padding: "unset"
            },        
            whatsappIcon: {
                width: "3.86vw",
                marginRight: "1.87vw"
            },
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "3.38vw" 
            },
            editButton: {
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.DeepBlack,
                marginRight: "1.93vw",
                padding: "0 3.86vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },            
            deleteButton: {
                width: "12.08vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Linen,
                marginRight: "1.93vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.Red
                }
            },
            viewButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28.98vw",
                height: "7.25vw",
                borderRadius: "1.69vw",
                background: Color.Nyanza,
                color: Color.MediumGreen,
                fontWeight: "700",
                marginLeft: "auto",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.MediumGreen,
                    marginRight: "1.21vw"
                }
            },
            addButtonTop: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "44.92vw",
                height: "7.73vw",
                border: "none",
                backgroundColor: Color.Orange,
                color: Color.White,
                borderRadius: "2.41vw",
                fontSize: "3.38vw",
                fontWeight: "bold",
                margin: "0 0 4.34vw auto",
                icon: {
                    width: "3.38vw",
                    height: "3.38vw",
                    fill: Color.White,
                    marginLeft: "2.41vw"
                }
            },
            addButtonBottom: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "63.04vw",
                height: "12.08vw",
                border: "none",
                backgroundColor: Color.Orange,
                color: Color.White,
                borderRadius: "2.41vw",
                fontSize: "3.86vw",
                fontWeight: "bold",
                margin: "10.86vw auto",
                icon: {
                    width: "4.13vw",
                    height: "4.13vw",
                    fill: Color.White,
                    marginLeft: "2.41vw"
                }
            },
            revenueWrapper: {
                borderTop: "2px solid " + Color.Green,
                marginTop: "3.54vw",
                padding: "3.54vw 0"
            },
            revenueText: {
                fontSize: "3.88vw",
                fontWeight: "bold",
                color: Color.PhilippineGray,
                marginBottom: "1.54vw"
            },
            revenueValue: {
                color: Color.Black
            },
            transferred: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Green
            },
            notTransferred: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Red
            },
            notCompleted: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.Red,
                padding: "3.54vw 0"
            },
            pdfViewer: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                overflow: "hidden",
                marginTop: "3.54vw"
            },
            iconLabel: {
                display: "flex",
                flexDirection: "row",
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh,
                margin: "3.62vw 0 0.61vw 0",
                justifyContent: "space-between",
                alignItems: "center"
            },
            videoUploadIcon: {
                width: "3.14vw",
                height: "auto",
                marginRight: "4.89vw",
                cursor: "pointer"
            },
            videoCloseIcon: {
                cursor: "pointer"
            },
            uploadFile: {
                display: "flex",
                flexDirection: "column",
                height: "51vw",
                width: "-webkit-fill-available",
                border: "1px dashed " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                cursor: "pointer",                
                WebkitTapHighlightColor: "transparent",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIconWrapper: {                
                width: "9.66vw",
                height: "9.66vw",
                borderRadius: "50%",
                backgroundColor: Color.LightAsh,
                marginBottom: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIcon: {
                width: "3.14vw",
                height: "auto"
            },
            uploadText: {
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh
            },
            submitButton: {
                height: "12.08vw",
                width: "-webkit-fill-available",
                color: Color.White,
                background: Color.MediumGreen,
                borderRadius: "2.41vw",
                fontSize: "4.35vw",
                fontWeight: "bold",
                margin: "4.41vw 0"
            }
        },
        detail: {
            image: {
                width: "-webkit-fill-available",
                margin: "50px 0 0 0"
            },
            container: {
                padding: "10px 5% 0 5%"
            },
            name: {
                fontSize: "6.04vw",
                fontWeight: "bold",
                color: Color.LightBlack
            },
            subjects: {
                fontSize: "3.86vw",
                fontWeight: "bold",
                color: Color.LightBlack,
                marginTop: "1.81vw"
            },
            grades: {
                fontSize: "3.86vw",
                color: Color.LightBlack,
                marginTop: "1.81vw"
            },
            payment: {
                fontSize: "3.86vw",
                color: Color.LightBlack,
                marginTop: "1.81vw"
            },
            description: {
                fontSize: "4.35vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh,
                marginTop: "4.83vw"
            },
            attendButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "12.08vw",
                width: "63.04vw",
                borderRadius: "2.41vw",
                backgroundColor: Color.Orange,
                color: Color.White,
                fontSize: "3.86vw",
                fontWeight: "bold",
                margin: "7.24vw auto"
            }
        },
        dialog: {
            popUp: {
                container: {
                    width: "calc(100% - 9.86vw)",
                    height: "unset",
                    padding: "7.246vw 4.93vw",
                    borderRadius: "2.415vw 2.415vw 0 0"
                }
            },
            title: {
                marginTop: "unset",
                marginBottom: "7vw",
                fontSize: "7.24vw",
                color: Color.LightBlack
            },
            message: {
                margin: "4.83vw 0 0 0",
                fontSize: "3.86vw",
                color: Color.DarkLiver,
                font: Font.Default,
                fontWeight: "600"
            },
            buttons: {
                justifyContent: "end"
            },
            button: {
                width: "50%",
                height: "12.07vw",
                fontWeight: "bold",
                fontSize: "4.83vw",
                borderRadius: "2.41vw",
                margin: "7.24vw 0 0 0",
                blue: {
                    border: "3px solid " + Color.Blue,
                    color: Color.Blue,
                    background: "transparent",
                    marginRight: "2.89vw",
                },
                red: {
                    border: "none",
                    color: Color.Red,
                    backgroundColor: Color.LightRed
                }
            }
        },
        studentList: {
            title: {
                fontSize: "4.86vw",
                fontWeight: "bold",
                color: Color.Black,
                textAlign: "center"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid " + Color.Green,
                padding: "2.54vw 0"
            },
            index: {
                marginRight: "1.87vw"
            },
            content: {
                width: "-webkit-fill-available"
            },
            name: {                
                fontSize: "3.38vw",
                fontWeight: "bold",
                marginBottom: "1.54vw"
            },
            dateWrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "3.38vw",
                fontWeight: "bold",
                color: Color.PhilippineGray
            }
        },
        videoPopUp: {
            container: {
                width: "calc(100% - 9.86vw)",
                height: "unset",
                padding: "7.246vw 4.93vw",
                borderRadius: "2.415vw 2.415vw 0 0"
            }
        }     
    },
    desktop: {       
        ...pageLayout.desktop,
        listCount: {
            fontSize: "1.34vw"
        },        
        filter: {
            position: "unset",
            margin: "20px 0 0 0",
            borderRadius: "0.73vw"
        },
        filterExpandButton: {
            height: "3.2vw",            
            paddingLeft: "1.62vw",
            paddingRight: "1.62vw",
            lineHeight: "3.2vw",
            borderRadius: "0.73vw",
            expand: {
                borderRadius: "0.73vw 0.73vw 0 0"
            }
        },        
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        },
        filterContent: {
            padding: "0 1.62vw 1.62vw 1.62vw"
        },
        classButton: {
            width: "13.59vw",
            height: "2.60vw",
            padding: "unset",
            margin: "20px 0 0 auto",
            fontSize: "0.83vw",
            borderRadius: "0.52vw",
            alignItems: "center",
            justifyContent: "center",
            noMargin: {
                margin: "20px 0 0 auto"
            }
        },
        class: {
            subContainer: {
                margin: "50px 0 50px 0"
            },
            imageWrapper: {
                width: "7.29vw"
            },
            image: {
                height: "7.29vw",
                width: "7.29vw",
                borderRadius: "8px"
            },
            detailWrapper: {
                width: "-webkit-fill-available",
                marginLeft: "12px"
            },
            name: {
                fontSize: "1.04vw"                
            },
            subjects: {
                fontSize: "1.04",
                marginTop: "0.42vw"
            },
            grades: {
                fontSize: "12px",
                marginTop: "0.42vw"
            },
            see: {
                fontSize: "14px",
                marginTop: "0.42vw"
            },
            buttonWrapper: {
                marginTop: "1.56vw" 
            },
            editButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.White,
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw"
                }
            },
            viewButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw",
                    fill: Color.Blue
                }
            },
            deleteButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.Red,
                marginRight: "0.83vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw"
                }
            },
            weekButton: {
                width: "13.50vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                icon: {
                    width: "0.80vw",
                    height: "0.80vw",
                    marginRight: "0.83vw"
                }
            }
        },        
        week: {
            container: { },
            card: {
                borderRadius: "0.52vw",  
                padding: "1.04vw 1.35vw",
                marginBottom: "0.52vw",
                cursor: "pointer"                
            },
            weekNo: {
                fontSize: "0.73vw"
            },
            title: {
                fontSize: "1.04vw"
            },
            dateWrapper: {
                marginTop: "0.625vw",
                fontSize: "0.83vw"
            },
            calendarIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw"
            },
            clockIcon: {
                height: "0.94vw",
                width: "0.94vw",
                marginRight: "0.81vw",
                marginLeft: "2.86vw"
            },
            studentCount: {
                marginRight: "0.31vw"
            },
            cashIcon: {
                height: "1.24vw",
                marginRight: "0.81vw",
                marginLeft: "2.86vw"
            },
            whatsappButton: {
                fontSize: "0.83vw",
                marginTop: "0.625vw"
            },        
            whatsappIcon: {
                width: "0.94vw",
                marginRight: "0.81vw"
            },
            buttonWrapper: {
                marginTop: "1.56vw" 
            },
            editButton: {
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.White,
                marginRight: "0.83vw",
                padding: "0 2.40vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw",
                    fill: Color.White
                }
            },
            deleteButton: {
                width: "6.25vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.83vw",
                fontWeight: "bold",
                color: Color.Red,
                marginRight: "0.83vw",
                icon: {
                    width: "3.95vw",
                    height: "3.51vw"
                }
            },
            viewButton: {
                width: "13.50vw",
                height: "1.82vw",
                borderRadius: "0.36vw",               
                icon: {
                    width: "0.94vw",
                    height: "0.94vw",
                    marginRight: "0.52vw"
                }
            },
            addButtonTop: {
                width: "10.94vw",
                height: "1.82vw",
                borderRadius: "0.36vw",
                fontSize: "0.73vw",
                margin: "0 0 1.04vw auto",
                icon: {
                    width: "0.73vw",
                    height: "0.73vw",
                    marginLeft: "0.52vw"
                }
            },
            addButtonBottom: {
                width: "13.59vw",
                height: "2.60vw",
                borderRadius: "0.52vw",
                fontSize: "0.83vw",
                margin: "2.60vw auto",
                icon: {
                    width: "0.88vw",
                    height: "0.88vw",
                    marginLeft: "0.52vw"
                }
            },
            revenueWrapper: {                
                marginTop: "0.625vw",
                padding: "0.625vw 0 0 0"
            },
            revenueText: {
                fontSize: "0.83vw",                
                marginBottom: "0.625vw"
            },
            transferred: {
                fontSize: "0.83vw"
            },
            notTransferred: {
                fontSize: "0.83vw"
            },
            notCompleted: {
                fontSize: "0.83vw",
                padding: "0.625vw 0"
            },
            pdfViewer: {
                borderRadius: "0.52vw",
                marginTop: "0.625vw"
            },
            iconLabel: {
                fontSize: "0.94vw",
                margin: "0.52vw 0 0.12vw 0"
            },
            videoUploadIcon: {
                width: "0.84vw",
                marginRight: "1.04vw"
            },
            uploadFile: {
                height: "17.45vw",
                borderRadius: "0.52vw"
            },
            uploadIconWrapper: {                
                width: "2.60vw",
                height: "2.60vw",
                marginBottom: "0.78vw"
            },
            uploadIcon: {
                width: "0.84vw"
            },
            uploadText: {
                fontSize: "0.94vw"
            },
            submitButton: {
                height: "2.64vw",
                borderRadius: "0.52vw",
                fontSize: "1.04vw",
                margin: "1.6vw 0 0 0"
            }
        },
        detail: {
            image: {
                margin: "20px 0 0 0",
                borderRadius: "0.52vw"
            },
            container: {
                padding: "10px 5% 0 5%"
            },
            name: {
                fontSize: "1.04vw"
            },
            subjects: {
                fontSize: "0.94vw",
                marginTop: "0.42vw"
            },
            grades: {
                fontSize: "0.83vw",
                marginTop: "0.42vw"
            },
            payment: {
                fontSize: "0.83vw",
                marginTop: "0.42vw"
            },
            description: {
                fontSize: "0.94vw",
                marginTop: "0.42vw"
            },
            attendButton: {
                height: "2.60vw",
                width: "13.59vw",
                borderRadius: "0.52vw",
                fontSize: "0.83vw",
                margin: "1.56vw auto"
            }
        },
        dialog: {
            popUp: {
                container: {
                    width: "21.33vw",
                    height: "unset",
                    padding: "3.489vw 3.54vw",
                    borderRadius: "0.52vw"
                }
            },
            title: {
                fontSize: "1.56vw",
                color: Color.LightBlack,
                marginBottom: "1.51vw"
            },
            message: {
                margin: "1.51vw 0 0 0",
                fontSize: "0.83vw",
                color: Color.DarkLiver,
                lineHeight: "1.5"
            },
            button: {
                width: "10.15vw",
                height: "2.604vw",
                fontSize: "1.041vw",
                borderRadius: "0.52vw",
                margin: "1.25vw 0 0 0",
                blue: {
                    border: "3px solid " + Color.Blue,
                    color: Color.Blue,
                    background: "transparent",
                    marginRight: "0.625vw",
                },
                red: {
                    border: "none",
                    color: Color.Red,
                    backgroundColor: Color.Linen
                }
            }
        },
        studentList: {
            title: {
                fontSize: "1.43vw"
            },
            rowWrapper: {
                padding: "0.75vw 0"
            },
            index: {
                marginRight: "0.81vw"
            },
            name: {                
                fontSize: "0.83vw",
                marginBottom: "0.25vw"
            },
            dateWrapper: {
                fontSize: "0.83vw"
            }
        },
        videoPopUp: {
            container: {
                width: "34%",
                height: "unset",
                padding: "1% 1% 1.6% 1%",
                borderRadius: "0.52vw"
            }
        } 
    }
}