import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StyleContext } from "../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { ToolBar } from "../components/Shared/ToolBar";
import { DesktopMenuDrawer } from "../components/Shared/DesktopMenuDrawer";
import { WebXPayModel } from "../models/WebXPayModel";
import { PopUpDialog } from "../components/Shared/Dialogs/PopUpDialog";
import { Button } from "../components/Shared/Button";
import { Input } from "../components/Shared/Inputs/Input";
import { InputValidation } from "../models/InputValidation";
import { validateEmail, validatePhoneNumber } from "../helpers/Common";
import { useAlert } from "../hooks/useAlert";
import { getPaymentModel } from "../services/CommonService";
import { SearchContentLoader } from "../loaders/SearchContentLoader";
import $ from 'jquery'

const paymentTypes = {
    publish: {
        typeId: 1,
        amount: "990",
        description: "Fill out the form and submit it to pay @fee to design and publish your tuition advertisement."
    },
    design: {
        typeId: 2,
        amount: "490",
        description: "Fill out the form and submit it to pay @fee to design your tuition advertisement."
    },
    boost: {
        typeId: 3,
        amount: "",
        description: "Fill out the form and submit it to pay @fee to boost your tuition advertisement."
    }
}

export const PaymentPage: React.FC = () => {  
    let params = useParams();   
    const navigate = useNavigate();
    const paymentTypeId: number = +params.typeId!;  
    const [paymentType, setPaymentType] = useState<any>();
    const [name, setName] = useState<string>("");
    const [nameValidation, setNameValidation] = useState<InputValidation>({});
    const [email, setEmail] = useState<string>("");
    const [emailValidation, setEmailValidation] = useState<InputValidation>({});
    const [whatsappNo, setWhatsappNo] = useState<string>("");
    const [whatsappValidation, setWhatsappValidation] = useState<InputValidation>({});
    const [amount, setAmount] = useState<string>("");
    const [amountValidation, setAmountValidation] = useState<InputValidation>({});
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("paymentPage")); 
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });       
    const [openDialog, setOpenDialog] = useState<boolean>(false); 
    const {Alert, openAlert} = useAlert();

    useEffect(() => {      
        switch (paymentTypeId) {
            case 1: {
                setPaymentType(paymentTypes.publish);
                setAmount(paymentTypes.publish.amount);
                break;
            }
            case 2: {
                setPaymentType(paymentTypes.design);
                setAmount(paymentTypes.design.amount);
                break;
            }
            case 3: {
                setPaymentType(paymentTypes.boost);
                break;
            }
        }
        setLoading(false);
    }, []);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("paymentPage"));        
    }, [isMobile]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "Name") {
            setName(value);
            if(nameValidation.invalid) {
                validateInput(name, value);
            }
        }
        else if(name === "Email") {
            setEmail(value);
            if(emailValidation.invalid) {
                validateInput(name, value);
            }
        }
        else if(name === "WhatsappNo") {
            setWhatsappNo(value);
            if(whatsappValidation.invalid) {
                validateInput(name, value);
            }
        }    
        else if(name === "Amount") {
            setAmount(value);
            if(amountValidation.invalid) {
                validateInput(name, value);
            }
        }    
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }
    
    const validateInput = (name: string, value: string) => {        
        if(name === "Name") {
            if(value === "") {
                setNameValidation({valid: false, invalid: true, invalidMessage: "Full name is required"});
            } 
            else {
                setNameValidation({valid: true, invalid: false});
            }
        }
        if(name === "Email") {            
            if(value === "") {
                setEmailValidation({valid: false, invalid: true, invalidMessage: "Email is required"});
            } 
            else if (!validateEmail(value)) {
                setEmailValidation({valid: false, invalid: true, invalidMessage: "Invalid email address!"});
            } 
            else {
                setEmailValidation({valid: true, invalid: false});
            }
        }
        if(name === "WhatsappNo") {            
            if(value === "") {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            } 
            else if (!validatePhoneNumber(value)) {
                setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number"});
            } 
            else {
                setWhatsappValidation({valid: true, invalid: false});
            }
        }
        if(name === "Amount") {
            if(value === "") {
                setAmountValidation({valid: false, invalid: true, invalidMessage: "Amount is required"});
            } 
            else if(!/^[1-9]\d*$/.test(value)) {
                setAmountValidation({valid: false, invalid: true, invalidMessage: "Invalid amount"});                
            }
            else {
                setAmountValidation({valid: true, invalid: false});
            }
        }         
    } 
    
    const validateForm = (): boolean => {
        let valid: boolean = true;        
        if(name === "") {
            setNameValidation({valid: false, invalid: true, invalidMessage: "Full name is required"});
            valid = false;
        }  
        if(email === "") {
            setEmailValidation({valid: false, invalid: true, invalidMessage: "Email is required"});
            valid = false;
        } 
        else if(!validateEmail(email)) {
            setEmailValidation({valid: false, invalid: true, invalidMessage: "Invalid email address!"});
            valid = false;
        }      
        if(whatsappNo === "") {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Whatsapp number is required"});
            valid = false;
        } 
        else if(!validatePhoneNumber(whatsappNo)) {
            setWhatsappValidation({valid: false, invalid: true, invalidMessage: "Invalid Whatsapp number!"});
            valid = false;
        } 
        if(amount === "") {
            setAmountValidation({valid: false, invalid: true, invalidMessage: "Amount is required"});
            valid = false;
        }  
        else if(!/^[1-9]\d*$/.test(amount)) {
            setAmountValidation({valid: false, invalid: true, invalidMessage: "Invalid amount"});
            valid = false;
        }     
        return valid;
    }
    
    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isFormValid: boolean = validateForm();
        if(isFormValid) {
            setOpenDialog(true); 
        } 
        else {
            openAlert("error", "Invalid form inputs.");
        }
    }

    async function submitPayment(isOnlinePayment: boolean) {
        setOpenDialog(false);        
        setLoading(true);
        await getPaymentModel(name, email, whatsappNo, +amount, isOnlinePayment)
        .then((payModel: any) => { 
            setLoading(false);                         
            if(isOnlinePayment) {     
                const webXPayModel: WebXPayModel = payModel;
                $('.pay').append('<form action="' + webXPayModel.PaymentUrl + '" id="form" name="form" method="post" style="display:none;">' +
                    '<input type="text" name="first_name" value="' + webXPayModel.FirstName + '" />' +
                    '<input type="text" name="last_name" value="' + webXPayModel.LastName + '" />' +
                    '<input type="text" name="email" value="' + webXPayModel.Email + '" />' +
                    '<input type="text" name="contact_number" value="' + webXPayModel.ContactNumber + '" />' +
                    '<input type="text" name="address_line_one" value="' + webXPayModel.Address + '" />' +
                    '<input type="text" name="process_currency" value="' + webXPayModel.Currency + '" />' +
                    '<input type="text" name="secret_key" value="' + webXPayModel.SecretKey + '" />' +
                    '<input type="text" name="custom_fields" value="' + webXPayModel.CustomFields + '" />' +
                    '<input type="text" name="payment" value="' + webXPayModel.PaymentStr + '" />' +
                '</form>');            
                var paymentForm = document.getElementById("form") as HTMLFormElement;                
                paymentForm.submit();
            }
            else {
                navigate('/bank-details', {
                    state: { 
                        paymentId: payModel,
                        price: amount 
                    }
                })
            }            
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                              
    }

    return (
        <>
            <ToolBar>{"Make Payments"}</ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                {loading ? <SearchContentLoader/> : <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{"Make Payments"}</h1>                    
                    <form style={styles.formWrapper} onSubmit={submitForm}>
                        <p style={styles.paymentMessage}>{paymentType.description.replace("@fee", amount)}</p>
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>
                                {"Full Name *"}
                            </label>
                            <Input
                                type={"text"}
                                name={"Name"}
                                value={name}
                                styles={styles.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                             
                                validation={nameValidation}    
                            />
                        </div>
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>
                                {"Email *"}
                            </label>
                            <Input
                                type={"text"}
                                name={"Email"}
                                value={email}
                                styles={styles.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                           
                                validation={emailValidation}    
                            />
                        </div>
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>
                                {"Whatsapp Number *"}
                            </label>
                            <Input
                                type={"text"}
                                name={"WhatsappNo"}
                                value={whatsappNo}
                                styles={styles.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                           
                                validation={whatsappValidation}    
                            />
                        </div>
                        {(paymentTypeId === 3) && <div style={styles.inputWrapper}>
                            <label style={styles.label}>
                                {"Amount (LKR) *"}
                            </label>
                            <Input
                                type={"text"}
                                name={"Amount"}
                                value={amount}                                
                                styles={styles.input}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}                                           
                                validation={amountValidation}    
                            />
                        </div>}
                        <Button type="submit" style={styles.submitButton}>                    
                            {"Submit"}                    
                        </Button>
                    </form>
                </div>}
                <div style={styles.rightWrapper}>
                                   
                </div>
            </div>
            <div className="pay"></div>           
            {openDialog && <PopUpDialog styles={styles.paymentDialog} toggle={() => setOpenDialog(!openDialog)}>                                
                <div style={styles.payButtonWrapper}>
                    <Button style={styles.onlinePayButton} type="button" onClick={() => submitPayment(true)}>
                        {"Pay Online"}
                    </Button>                    
                    <Button style={styles.bankPayButton} type="button" onClick={() => submitPayment(false)}>
                        {"Bank Deposit"}
                    </Button>
                </div>
            </PopUpDialog>}
            <Alert/>
        </>        
    );
}