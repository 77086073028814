import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const desktopMenu = {
    mobile: {  
        display: "none", 
        flexDirection: "column" as "column",       
        position: "fixed",                                            
        height: "100%",  
        width: "25%",              
        top: "0",
        left: "0",
        marginRight: "auto",
        background: Color.DeepBlack,        
        zIndex: "2", 
        title: {                        
            marginTop: "50px", 
            textAlign: "center" as "center",                
            fontSize: "46px",        
            fontFamily: Font.Advanced,        
            letterSpacing: "0px",
            color: Color.AquaGreen,            
            cursor: "pointer",
            opacity: "1"
        },   
        menu: {        
            position: "relative" as "relative",            
            display: "flex",
            flexDirection: "column" as "column", 
            paddingTop: "60px",            
            alignItems: "center" as "center",             
            label: {   
                textDecoration: "none",      
                fontSize: "20px",                
                color: Color.White                         
            },
            itemWrapper: {
                marginBottom: "40px"
            }
        }        
    },
    desktop: {        
        display: "flex"        
    }
}
