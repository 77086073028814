import Axios from "axios";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import { ContactModel } from "../models/ContactModel";
import { getLanguageCode } from "../helpers/Common";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function getPageLocalizations(appModuleId: number, appPageId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/basic/appLocalizationsByPage", {            
            params: {
                appModuleId: appModuleId,
                appPageId: appPageId,
                languageCode: getLanguageCode()
            },                   
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }            
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getAllLanguages() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/setting/allLanguages", {                               
            cache: {                
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getBanners(bannerTypeId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "banners/byBannerType", {
            params: {
                bannerTypeId: bannerTypeId
            },                   
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateBannerClickCount(bannerId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .patch(Texts.apiBaseUrl + "banners/bannerCount", {
            params: {
                bannerId: bannerId
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postContactRequest(contactModel: ContactModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(Texts.apiBaseUrl + "common/basic/contact", contactModel, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getPaymentModel(fullName: string, email: string, whatsappNo: string, amount: number, isOnlinePayment: boolean) {     
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "common/basic/common-pay-model", {                                
            params: {
                fullName: fullName,
                email: email,
                whatsappNo: whatsappNo,
                amount: amount,
                isOnlinePayment: isOnlinePayment                          
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}