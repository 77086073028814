import React, { useContext, useEffect, useState } from 'react';
import { Option } from '../../../models/Option';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { merge } from 'react-merge';
import { getLanguageCode } from '../../../helpers/Common';

interface Props { 
    styles?: any;
    streamId?: number;      
    title: string;
    options: Option[];
    subjectSelectionError?: string;
    getSelected(selectedOptions: Option[]): void;
    openToastMessage?(message: string): void;
    closeDialog(): void;    
};

export const SingleSelectDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [options, setOptions] = useState<Option[]>(props.options);             
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = getLanguageCode();           

    useEffect(() => {   
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);                                                                  
    }, [isMobile]);

    const itemChecked = (index: number) => {        
        let tempOptions = [...options];
        let prevSelectIndex = tempOptions.findIndex(({ Checked: checked }) => checked === true);
        if(prevSelectIndex != -1) {        
            tempOptions[prevSelectIndex].Checked = false;
        }
        tempOptions[index].Checked = true;        
        setOptions(tempOptions); 
        props.getSelected(tempOptions);       
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>
                <div style={styles.title}>{props.title}</div>
                <div style={styles.container} className={"selector"}>
                    {options.map((option, index) =>                                     
                        <div key={index} style={styles.optionWrapper} onClick={() => itemChecked(index)}>                                                    
                            <input type={"radio"} checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                            <div style={styles.option}>
                                {option.PrimaryOption}    
                                {languageCode !== "ENG" && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                            </div>
                        </div>                    
                    )}    
                </div>
            </div> 
        </>       
    );
}