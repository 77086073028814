import Axios from "axios";
import { saveAs } from 'file-saver';
import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import { CommonTexts as Texts} from "../helpers/LayoutTexts";
import { TeacherSearchResponseModel } from "../models/TeacherSearchResponseModel";
import { getLanguageCode } from "../helpers/Common";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });

export async function getOnlineClass(classId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/class", {            
            params: {
                classId: classId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getOnlineClassWeek(weekId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/weekWithExpiry", {            
            params: {
                weekId: weekId
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getSearchRequestModel(gradeCategoryIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/searchRequestModel", {            
            params: {
                gradeCategoryIds: gradeCategoryIds ? gradeCategoryIds : "",                
                languageCode: getLanguageCode()
            },            
            cache: {                
                ttl: 1000 * 60 * 60 * 24 // 1 day
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postSearchResponseModel(responseModel: TeacherSearchResponseModel) {
    responseModel.LanguageCode = getLanguageCode();
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/searchResponseModel", responseModel, {                                
            cache: {    
                methods: ["post"],            
                ttl: 1000 * 60 * 5 // 5 min
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassDescription(classId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/classWithDetails", {                                
            params: {
                classId: classId,
                languageCode: getLanguageCode()           
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function saveOnlineClass(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/saveClass", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteOnlineClass(classId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/deleteClass", null, {                                
            params: {
                classId: classId            
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function saveClassWeek(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/saveClassWeek", formData, {
            headers: {                
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteOnlineClassWeek(weekId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/deleteClassWeek", null, {                                
            params: {
                weekId: weekId            
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassesByTeacher(page: number, pageSize: number, teacherId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/classesByTeacher", {                                
            params: {
                page: page,
                pageSize: pageSize,
                teacherId: teacherId,
                languageCode: getLanguageCode()             
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassWeeksByTeacher(classId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/classWeeksByTeacher", {                                
            params: {
                classId: classId            
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassesByStudent(page: number, pageSize: number, studentId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/classesByStudent", {                                
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: {
                page: page,
                pageSize: pageSize,
                studentId: studentId,
                languageCode: getLanguageCode()             
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getClassWeeksByStudent(classId: number, studentId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/weeksByStudent", {                                
            params: {
                classId: classId,
                studentId: studentId                          
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getWeekPayModel(classId: number, weekId: number, studentId: number, fee: number, isOnlinePayment: boolean) {     
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/weekPayModel", {                                
            params: {
                classId: classId,
                weekId: weekId,
                studentId: studentId,
                fee: fee,
                isOnlinePayment: isOnlinePayment                          
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getZoomSDKSignature(studentId: number, classId: number, weekId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/zoomSDKSignature", {    
            headers: {
                'Authorization' : `Bearer ${token}`
            },                            
            params: {
                studentId: studentId,
                classId: classId,
                weekId: weekId                          
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getZoomRecordingEligibility(studentId: number, classId: number, weekId: number, token: string): Promise<any> {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/zoom-recording-eligibility", {    
            headers: {
                'Authorization' : `Bearer ${token}`
            },                            
            params: {
                studentId: studentId,
                classId: classId,
                weekId: weekId                          
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function downloadTuteByStudent(weekId: number, tuteName: string, studentId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/tuteByStudent", {  
            headers: {
                'Authorization' : `Bearer ${token}`
            },                              
            params: {
                weekId: weekId,  
                studentId: studentId          
            },
            responseType: 'blob',
            cache: false
        })
        .then((response) => {                
            var blob = new Blob([response.data], {
                type: response.headers['content-type']
            });            
            saveAs(blob, tuteName); 
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getWeekPaidStudents(weekId: number, teacherId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/paidStudents", {                                
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: {
                weekId: weekId,
                teacherId: teacherId           
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getWeekAttendStudents(weekId: number, teacherId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/attendStudents", {                                
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: {
                weekId: weekId,
                teacherId: teacherId           
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getRecordingUploadURL(teacherId: number, token: string, weekId: number, contentType: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(Texts.apiBaseUrl + "onlineClass/recording-upload-url", {                                
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            params: {
                teacherId: teacherId,
                weekId: weekId,
                contentType: contentType                             
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function uploadZoomRecordingVideo(uploadURL: string, video: File) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .put(uploadURL, video, {                                
            headers: {
                "Content-Type": video.type
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateRecordingLink(teacherId: number, token: string, weekId: number, timestamp: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(Texts.apiBaseUrl + "onlineClass/update-recording-link", null, {  
            headers: {
                'Authorization' : `Bearer ${token}`
            },                              
            params: {
                teacherId: teacherId,
                weekId: weekId,
                timestamp: timestamp            
            }
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}