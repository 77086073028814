import { useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedStudent } from "../../models/LoggedStudent";
import { getZoomRecordingEligibility } from "../../services/ClassService";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import LogoLoader from "../../loaders/LogoLoader";
import { validateStudentLogin } from "../../services/StudentService";
import { useMediaQuery } from "react-responsive";
import useErrorPage from "../../hooks/useErrorPage";
import { UserType } from "../../enums/UserType";
import VideoPlayer from "../../components/Shared/VideoPlayer";
import { ResponseModel } from "../../models/ResponseModel";

interface StateProps {
    week: OnlineClassWeek;
}

export const VideoStreamingPage: React.FC = () => {
    const navigate = useNavigate();  
    const location = useLocation();
    const state = location.state as StateProps;
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("studentClassPage")); 
    const authContext = useContext(AuthContext);
    const student: LoggedStudent | null = authContext.getLoggedStudent();
    const week = state ? state.week : undefined;
    const [videoUrl, setVideoUrl] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true); 
    const [unauthorized, setUnauthorized] = useState<boolean>(false);     
    const [toErrorPage] = useErrorPage();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 

    useEffect(() => {
        if(!week) {
            setLoading(false);
            setUnauthorized(true);
        }
        else if(student === null) {
            const searchParams = { callbackUrl: "/student/class-weeks/" + week.OnlineClassId, message: "Please login or register as student to access the class recording page." };
            navigate({
                pathname: '/student/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
        else {
            getRecordingVideoEligibility();
        }         
        const interval = setInterval(validateUserLogin, 300000);
        return () => {
            clearInterval(interval);
            if (videoUrl) {
                URL.revokeObjectURL(videoUrl); // Clean up the object URL
            }
        }
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("studentClassPage"));        
    }, [isMobile]);

    async function getRecordingVideoEligibility() {                
        await getZoomRecordingEligibility(student!.StudentId, week!.OnlineClassId, week!.OnlineClassWeekId, student!.Token)
        .then((response: ResponseModel) => {
            if(response.Status) {            
                setVideoUrl(response.Data);
                setLoading(false);                                       
            }
            else {
                setLoading(false);
                setUnauthorized(true);
            }
        })
        .catch((error) => {
            toErrorPage(error, UserType.Student);            
        });                            
    }

    async function validateUserLogin() {
        await validateStudentLogin(student!.StudentId, student!.Token)
        .then((isValidLogin: boolean) => {
            if(!isValidLogin) {
                localStorage.removeItem("student");
                localStorage.removeItem("token");   
                const searchParams = { callbackUrl: "/student/class-weeks/" + week!.OnlineClassId, message: "Please login or register as student to access the class recording page." };
                navigate({
                    pathname: '/student/login',
                    search: `?${createSearchParams(searchParams)}`
                });                   
            }
        })
        .catch((error) => {
            toErrorPage(error, UserType.Student);
        });
    }
      
    return (
        <>
            {loading && <LogoLoader />}
            {unauthorized && <div style={styles.streaming.unauthorized}>Unauthorized Access</div>}             
            {videoUrl && <VideoPlayer videoUrl={videoUrl} />}            
        </>
    );
}