import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';
import { ReactComponent as CheckCircle } from '../../assets/svgs/check-circle.svg';
import { ReactComponent as Warning } from '../../assets/svgs/warning-triangle.svg';
import { ReactComponent as CloseCircle } from '../../assets/svgs/close-circle-red.svg';

interface Props {    
    isOpen: boolean;
    status?: string;            
    message?: string;    
};

export const ResponseAlert: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("responseAlert")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const [isOpen] = useState(props.isOpen);    
    
    useEffect(() => {    
        // merge base styles with status specific styles 
        let baseStyles = styleContext.getComponentStyle("responseAlert"); 
        let statusStyles: any;
        switch (props.status) {
            case "success":
                statusStyles = baseStyles.success;
                break;
            case "error":
                statusStyles = baseStyles.error;
                break;
            case "warning":
                statusStyles = baseStyles.warning;
                break;
            case "normal":
                statusStyles = baseStyles.normal;
                break;            
            default:
                statusStyles = baseStyles.normal;
                break;
        }        
        let extendedStyles = merge(baseStyles.default, statusStyles);
        setStyles(extendedStyles);                                                                  
    }, [isMobile]);
    
    return isOpen ? (
        <div style={styles}>
            {props.status === "success" && <CheckCircle style={styles.icon}/>}
            {props.status === "error" && <CloseCircle style={styles.icon}/>}
            {props.status === "warning" && <Warning style={styles.icon}/>}
            {props.message}
        </div>
    ) : null;
}