import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { merge } from 'react-merge';
import { MenuOption } from '../../../models/MenuOption';
import { ReactComponent as Dropdown } from "../../../assets/svgs/drop_down.svg";
import { getLanguageCode } from '../../../helpers/Common';

interface Props { 
    styles?: any;        
    title: string;    
    menuOptions: MenuOption[];    
    labelId: number;
    optionId: number;
    getMenuSelected(menuOption: MenuOption[], labelId: number, optionId: number): void;     
    closeDialog(): void;    
};

export const SingleSelectMenuDialog: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dialog"));
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>(props.menuOptions);  
    const [labelId, setLabelId] = useState<number>(props.labelId);   
    const [optionId, setOptionId] = useState<number>(props.optionId);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const languageCode = getLanguageCode();           

    useEffect(() => {           
        let style = styleContext.getComponentStyle("dialog");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);

    const itemChecked = (selectedLabelId: number, selectedOptionId: number) => {        
        let tempMenuOptions = [...menuOptions];        
        if(labelId !== -1 && optionId !== -1) {
            tempMenuOptions.find(menuOption => menuOption.LabelId === labelId)!.Options.find(option => option.Value === optionId)!.Checked = false;                                
        } 
        tempMenuOptions.find(menuOption => menuOption.LabelId === selectedLabelId)!.Options.find(option => option.Value === selectedOptionId)!.Checked = true;           
        setMenuOptions(tempMenuOptions);          
        setLabelId(selectedLabelId);
        setOptionId(selectedOptionId);   
        props.getMenuSelected(tempMenuOptions, selectedLabelId, selectedOptionId); 
    }

    const itemExpand = (selectedParentIndex:number) => {
        let tempMenuOptions = [...menuOptions];
        tempMenuOptions[selectedParentIndex].Expand = !tempMenuOptions[selectedParentIndex].Expand;
        setMenuOptions(tempMenuOptions);
    }

    return (
        <>
            <div style={styles.backdrop} onClick={props.closeDialog}></div>
            <div style={styles.dialog}>        
                <div style={styles.title}>{props.title}</div>
                <div style={styles.container} className={"selector"}>
                    {menuOptions.map((menuOption, parentIndex) => 
                        <div key={parentIndex}>
                            <div style={styles.labelWrapper} onClick={() => itemExpand(parentIndex)}>
                                {menuOption.Label}
                                <Dropdown style={styles.labelIcon} />
                            </div>
                            <div style={menuOption.Expand ? styles.expand : styles.minimize}>
                                {menuOption.Options.map((option, childIndex) => 
                                    <div key={childIndex} style={styles.optionWrapper} onClick={() => itemChecked(menuOption.LabelId, option.Value)}>                                                    
                                        <input type="radio" checked={option.Checked} style={option.Checked ? styles.checked : styles.unChecked} readOnly/>
                                        <div style={styles.option}>
                                            {option.PrimaryOption}    
                                            {languageCode !== "ENG" && <div style={styles.secondaryOption}>{option.SecondaryOption}</div>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>                                                                            
                    )}
                </div>
            </div> 
        </>       
    );
}