import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const paymentPage = {
    mobile: {
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",             
            padding: "0 5%"            
        },
        paymentMessage: {
            fontSize: "4.34vw",
            marginBottom: "5.79vw"
        },
        formWrapper: {
            marginTop: "85px"
        },
        inputWrapper: {
            marginBottom: "4.83vw"
        },
        label: {
            fontSize: "3.86vw",
            fontFamily: Font.Poppins,
            color: Color.DeepAsh,
            margin: "0 0 1.21vw 3.62vw"
        },
        input: {                       
            wrapper: {
                margin: "auto",
                alignItems: "center"
            },               
            input: { 
                height: "11.08vw",
                padding: "0 3.62vw",                    
                fontSize: "3.86vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                borderBottom: "1px solid " + Color.PhilippineGray,
                valid: {
                    backgroundColor: Color.AliceBlue,
                    border: "1px solid " + Color.Blue,
                    borderBottom: "1px solid " + Color.Blue
                },
                invalid: {
                    backgroundColor: Color.CoralRed,
                    border: "1px solid " + Color.Red,
                    borderBottom: "1px solid " + Color.Red,
                    color: Color.Red
                }
            }
        },
        submitButton: {
            height: "12.08vw",
            width: "-webkit-fill-available",
            color: Color.White,
            background: Color.MediumGreen,
            borderRadius: "2.41vw",
            fontSize: "4.35vw",
            fontWeight: "bold",
            margin: "2.41vw 0 7.25vw 0"
        },
        paymentDialog: {            
            backdrop: {
                backgroundColor: Color.Black,
                opacity: 0.15,
                position: "fixed" as "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0"
            },
            container: {
                background: Color.White,
                position: "fixed",
                top: "35%",
                right: "5%",
                width: "80%",
                height: "auto",
                padding: "10px 5%",                        
                borderRadius: "10px",
                zIndex: "2",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",  
                transform: "translateX(0)"
            }
        },
        payButtonWrapper: {
            display: "flex",
            flexDirection: "column",
            margin: "24px 0 10px 0"
        },
        onlinePayButton: {
            height: "40px",
            background: Color.MediumGreen,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        },
        bankPayButton: {
            height: "40px",
            background: Color.Black,
            color: Color.White,
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "12px"
        }
    },
    desktop: {
        desktopTitle: {
            display: "block",            
            textAlign: "center" as "center",
            color: Color.Green,
            background: Color.White,
            margin: 0,
            zIndex: 1
        },
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        },
        paymentMessage: {
            fontSize: "1.14vw",
            marginBottom: "1.875vw"
        },
        formWrapper: {
            marginTop: "unset"
        },
        inputWrapper: {
            marginBottom: "1.56vw"
        },
        label: {
            fontSize: "0.94vw",
            margin: "0 0 0.52vw 0.52vw"
        },
        input: {              
            input: { 
                height: "3.04vw",
                padding: "0 1.56vw",
                fontSize: "0.83vw",
                borderRadius: "0.52vw"
            }
        },
        submitButton: {
            height: "3.64vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw",
            margin: "0.52vw 0 2.08vw 0"
        },
        paymentDialog: {
            container:{
                top: "28%",
                right: "unset",
                width: "32.6%",
                padding: "2% 1.7%",
                borderRadius: "10px",
                boxShadow: "1px 0px 7px rgba(0,0,0,0.5)"
            }
        },
        payButtonWrapper: {
            margin: "42px 0 10px 0"
        },
        onlinePayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        },
        bankPayButton: {
            height: "50px",           
            borderRadius: "10px",            
            fontSize: "16px"
        }
    }
}