import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const classManagePage = {
    mobile: {
        desktopTitle: {
            display: "none"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",             
            padding: "0 5%"            
        },
        class: {
            formWrapper: {
                marginTop: "85px"
            },
            inputWrapper: {
                marginBottom: "4.83vw"
            },
            radioWrapper: {
                display: "flex",
                flexDirection: "row",
                padding: "0 15%",    
                justifyContent: "space-between",
                marginTop: "1.61vw"
            },
            label: {
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh,
                margin: "0 0 1.21vw 3.62vw"
            },
            input: {
                wrapper: {
                    margin: "auto",
                    alignItems: "center"
                },               
                input: { 
                    height: "11.08vw",
                    padding: "0 3.62vw",                    
                    fontSize: "3.86vw",
                    border: "1px solid " + Color.PhilippineGray,
                    borderRadius: "1.21vw",
                    borderBottom: "1px solid " + Color.PhilippineGray,
                    valid: {
                        backgroundColor: Color.AliceBlue,
                        border: "1px solid " + Color.Blue,
                        borderBottom: "1px solid " + Color.Blue
                    },
                    invalid: {
                        backgroundColor: Color.CoralRed,
                        border: "1px solid " + Color.Red,
                        borderBottom: "1px solid " + Color.Red,
                        color: Color.Red
                    }
                }
            },
            imageWarning: {
                margin: "40px 0 5px 0",
                fontSize: "12px"
            },
            imageInput: {
                height: "auto",  
                width: "-webkit-fill-available",            
                border: "none",
                borderRadius: "5px",            
                objectFit:"cover",
                cursor: "pointer"
            },
            imageInvalid: {
                border: "1px solid " + Color.Red,            
                WebkitFilter: "grayscale(1)", 
                filter: "grayscale(1)"
            },
            editorLabel: {
                margin: "40px 0 8px 10px"
            },
            editor: {
                height: "unset",
                minHeight: "60.86vw",            
                border: "0.24vw solid" + Color.MiddleAsh,
                borderRadius: "1.21vw"
            },
            submitButton: {
                height: "40px",
                width: "-webkit-fill-available",
                background: Color.Green,
                color: Color.White,
                border: "none",
                borderRadius: "1.21vw",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "30px",
                marginBottom: "15px"
            }
        },
        week: {
            formWrapper: {
                marginTop: "85px"
            },
            inputWrapper: {
                marginBottom: "4.83vw"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            },
            fullWrapper: {
                width: "-webkit-fill-available"
            },
            halfWrapper: {
                width: "49%"
            },
            label: {
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh,
                margin: "0 0 1.21vw 3.62vw"
            },
            labelWarning: {
                fontSize: "3.00vw",
                marginLeft: "3.62vw"
            },
            input: {                       
                wrapper: {
                    margin: "auto",
                    alignItems: "center"
                },               
                input: { 
                    height: "11.08vw",
                    padding: "0 3.62vw",                    
                    fontSize: "3.86vw",
                    border: "1px solid " + Color.PhilippineGray,
                    borderRadius: "1.21vw",
                    borderBottom: "1px solid " + Color.PhilippineGray,
                    valid: {
                        backgroundColor: Color.AliceBlue,
                        border: "1px solid " + Color.Blue,
                        borderBottom: "1px solid " + Color.Blue
                    },
                    invalid: {
                        backgroundColor: Color.CoralRed,
                        border: "1px solid " + Color.Red,
                        borderBottom: "1px solid " + Color.Red,
                        color: Color.Red
                    }
                }
            },
            switch: {
                wrapper: {
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4.83vw"
                },
                label: {
                    fontSize: "3.86vw",
                    fontFamily: Font.Poppins,
                    color: Color.DeepAsh,
                    margin: "0 0 0 3.62vw"                              
                }
            },
            uploadFile: {
                display: "flex",
                flexDirection: "column",
                height: "51vw",
                width: "-webkit-fill-available",
                border: "1px dashed " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                cursor: "pointer",                
                WebkitTapHighlightColor: "transparent",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIconWrapper: {                
                width: "9.66vw",
                height: "9.66vw",
                borderRadius: "50%",
                backgroundColor: Color.LightAsh,
                marginBottom: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIcon: {
                width: "3.14vw",
                height: "auto"
            },
            uploadText: {
                fontSize: "3.86vw",
                fontFamily: Font.Poppins,
                color: Color.DeepAsh
            },
            pdfViewer: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "49%",  
                height: "51vw",
                border: "1px solid " + Color.PhilippineGray,
                borderRadius: "1.21vw",
                overflow: "hidden"
            },
            fileClose: {
                fill: Color.Black,
                margin: "1.45vw 0 0 -10.14vw",
                cursor: "pointer"
            },          
            editor: {
                height: "unset",
                minHeight: "60.86vw",            
                border: "0.24vw solid" + Color.MiddleAsh,
                borderRadius: "1.21vw"
            },
            submitButton: {
                height: "12.08vw",
                width: "-webkit-fill-available",
                color: Color.White,
                background: Color.MediumGreen,
                borderRadius: "2.41vw",
                fontSize: "4.35vw",
                fontWeight: "bold",
                margin: "2.41vw 0 7.25vw 0"
            }
        }
    },
    desktop: { 
        desktopTitle: {
            display: "block",
            textAlign: "center" as "center",
            color: Color.Green            
        },        
        container: { 
            flexDirection: "row" as "row",
            margin: "35px 0 20px 0",     
            padding: "0 8% 0 32%"            
        }, 
        leftWrapper: {
            width: "60%"
        },
        rightWrapper: {
            width: "34%",
            margin: "60px 0 0 6%"
        },
        class: {
            formWrapper: {
                marginTop: "unset"
            },
            inputWrapper: {
                marginBottom: "1.56vw"
            },
            label: {
                fontSize: "0.94vw",
                margin: "0 0 0.52vw 0.52vw"
            },
            radioWrapper: {
                display: "flex",
                flexDirection: "row",
                padding: "0 15%",    
                justifyContent: "space-between",
                marginTop: "1.61vw"
            },
            input: {              
                input: {
                    height: "3.04vw",
                    padding: "0 1.56vw",
                    fontSize: "0.83vw",
                    borderRadius: "0.52vw"
                }
            },
            imageInput: {
                height: "auto",
                borderRadius: "10px"            
            }, 
            editor: {
                minHeight: "22.76vw",
                width: "-webkit-fill-available",
                border: "0.05vw solid" + Color.MiddleAsh,
                borderRadius: "0.52vw"
            },            
            submitButton: {
                height: "60px",
                borderRadius: "10px",
                fontSize: "20px",
                marginTop: "40px",
                marginBottom: "30px"
            }
        },
        week: {
            formWrapper: {
                marginTop: "unset"
            },
            inputWrapper: {
                marginBottom: "1.56vw"
            },
            label: {
                fontSize: "0.94vw",
                margin: "0 0 0.52vw 0.52vw"
            },
            labelWarning: {
                fontSize: "0.64vw",
                marginLeft: "0.52vw"
            },
            input: {              
                input: { 
                    height: "3.04vw",
                    padding: "0 1.56vw",
                    fontSize: "0.83vw",
                    borderRadius: "0.52vw"
                }
            },
            switch: {
                wrapper: {                
                    marginTop: "unset",
                    marginBottom: "1.56vw"
                },
                label: {
                    fontSize: "0.94vw",
                    margin: "0 0 0 0.52vw"                             
                }
            },
            uploadFile: {
                height: "17.45vw",
                borderRadius: "0.52vw"
            },
            uploadIconWrapper: {                
                width: "2.60vw",
                height: "2.60vw",
                marginBottom: "0.78vw"
            },
            uploadIcon: {
                width: "0.84vw"
            },
            uploadText: {
                fontSize: "0.94vw"
            },
            pdfViewer: { 
                height: "17.45vw",                
                borderRadius: "0.52vw"
            },
            fileClose: {
                margin: "0.35vw 0 0 -3.14vw"
            },
            editor: {
                minHeight: "22.76vw",
                width: "-webkit-fill-available",
                border: "0.05vw solid" + Color.MiddleAsh,
                borderRadius: "0.52vw"
            }, 
            submitButton: {
                height: "3.64vw",
                borderRadius: "0.52vw",
                fontSize: "1.04vw",
                margin: "0.52vw 0 2.08vw 0"
            }
        }
    }
}