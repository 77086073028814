import './App.css';
import { lazy, Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { StyleContextProvider } from './providers/StyleContextProvider';
import { HelmetProvider } from 'react-helmet-async';
import { HomePage } from './pages/HomePage';
import { GovernmentDegreeListPage } from './pages/Degree/GovernmentDegreeListPage';
import { GovernmentDegreeDisplayPage } from './pages/Degree/GovernmentDegreeDisplayPage';
import { PrivateDegreeListPage } from './pages/Degree/PrivateDegreeListPage';
import { PrivateDegreeDisplayPage } from './pages/Degree/PrivateDegreeDisplayPage';
import { GovernmentDiplomaListPage } from './pages/Diploma/GovernmentDiplomaListPage';
import { GovernmentDiplomaDisplayPage } from './pages/Diploma/GovernmentDiplomaDisplayPage';
import { PrivateDiplomaListPage } from './pages/Diploma/PrivateDiplomaListPage';
import { PrivateDiplomaDisplayPage } from './pages/Diploma/PrivateDiplomaDisplayPage';
import { NewAdPage } from './pages/Teacher/NewAdPage';
import { AdListPage } from './pages/Teacher/AdListPage';
import { TeacherProfilePage } from './pages/Teacher/TeacherProfilePage';
import { SettingsPage } from './pages/SettingsPage';
import { AuthContextProvider } from './providers/AuthContextProvider';
import { ChatPage } from './pages/ChatPage';
import { PaymentResponsePage } from './pages/PaymentResponsePage';
import { AdViewPage } from './pages/Teacher/AdViewPage';
import { CashPaymentPage } from './pages/Teacher/CashPaymentPage';
import { ClassListPage } from './pages/OnlineClass/ClassListPage';
import { TeacherClassPage } from './pages/OnlineClass/TeacherClassPage';
import { StudentClassPage } from './pages/OnlineClass/StudentClassPage';
import { ClassManagePage } from './pages/OnlineClass/ClassManagePage';
import { TeacherClassWeekPage } from './pages/OnlineClass/TeacherClassWeekPage';
import { WeekManagePage } from './pages/OnlineClass/WeekManagePage';
import { ScrollToTop } from './components/Shared/ScrollToTop';
import { ClassDescriptionPage } from './pages/OnlineClass/ClassDescriptionPage';
import { StudentClassWeekPage } from './pages/OnlineClass/StudentClassWeekPage';
import { StudentLoginPage } from './pages/Student/StudentLoginPage';
import { TeacherLoginPage } from './pages/Teacher/TeacherLoginPage';
import { ZoomStreamingPage } from './pages/OnlineClass/ZoomStreamingPage';
import { VideoStreamingPage } from './pages/OnlineClass/VideoStreamingPage';
import { PaymentPage } from './pages/PaymentPage';

const TermsAndConditionsPage = lazy(() => import('./pages/TermsAndConditionsPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const BankDetailsPage = lazy(() => import('./pages/BankDetailsPage'));
const ZoomOAuthPage = lazy(() => import('./pages/ZoomOAuthPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePage/> },
    { path: "student/:stage", element: <StudentLoginPage/> },
    { path: "teacher/:stage", element: <TeacherLoginPage/> },
    { path: "government-degrees/:seo", element: <GovernmentDegreeListPage/> },
    { path: "government-degree/view/:degreeId/:seo", element: <GovernmentDegreeDisplayPage/> },
    { path: "private-degrees/:seo", element: <PrivateDegreeListPage/> },
    { path: "private-degree/view/:degreeId/:seo", element: <PrivateDegreeDisplayPage/> },       
    { path: "government-diplomas/:seo", element: <GovernmentDiplomaListPage/> },
    { path: "government-diploma/view/:diplomaId/:seo", element: <GovernmentDiplomaDisplayPage/> },    
    { path: "private-diplomas/:seo", element: <PrivateDiplomaListPage/> },
    { path: "private-diploma/view/:diplomaId/:seo", element: <PrivateDiplomaDisplayPage/> },    
    { path: "teachers/:seo", element: <AdListPage/> },
    { path: "teacher-ads/:tab", element: <NewAdPage/> },
    { path: "teacher-ads/view/:adId/:seo", element: <AdViewPage/> },
    { path: "teacher-profile/:teacherId", element: <TeacherProfilePage/> },
    { path: "teacher-ads/cash-payment/:paymentId", element: <CashPaymentPage/> },
    { path: "online-classes/:seo", element: <ClassListPage/> },
    { path: "online-class/description/:classId/:seo", element: <ClassDescriptionPage/> },
    { path: "teacher/online-classes", element: <TeacherClassPage/> },
    { path: "teacher/class-weeks/:classId", element: <TeacherClassWeekPage/> },
    { path: "teacher/class-manage/:classId", element: <ClassManagePage/> },
    { path: "teacher/week-manage/:classId/:weekId", element: <WeekManagePage/> },
    { path: "student/online-classes", element: <StudentClassPage/> },    
    { path: "student/class-weeks/:classId", element: <StudentClassWeekPage/> },        
    { path: "online-class/streaming", element: <ZoomStreamingPage/> },
    { path: "online-class/recording", element: <VideoStreamingPage/> },
    { path: "settings", element: <SettingsPage/> },
    { path: "chat", element: <ChatPage/> },
    { path: "payment/:typeId", element: <PaymentPage/> }, 
    { path: "payment-response", element: <PaymentResponsePage/> },
    { path: "termsAndConditions", element: <TermsAndConditionsPage/> },
    { path: "privacyPolicy", element: <PrivacyPolicyPage/> },
    { path: "contact", element: <ContactPage/> },
    { path: "bank-details", element: <BankDetailsPage/> },
    { path: "zoom/oauth", element: <ZoomOAuthPage/> },
    { path: "error", element: <ErrorPage/> },
    { path: "*", element: <HomePage/> }
  ]);
  return routes;
};

function App() {
  return (
    <StyleContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <HelmetProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollToTop/>
              <AppRoutes/>
            </Suspense> 
          </HelmetProvider>          
        </BrowserRouter>
      </AuthContextProvider>      
    </StyleContextProvider> 
  );
}

export default App;
