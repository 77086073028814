import React, { useContext, useEffect, useState } from 'react';
import { Button } from './Button';
import { ReactComponent as Icon } from '../../assets/svgs/drop_down.svg';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

interface Props {    
    styles?: any;
    children?: React.ReactNode;
    items: any[]
}

export const Dropdown: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("dropdown"));
    const [hover, setHover] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {           
        let style = styleContext.getComponentStyle("dropdown");
        let extendedStyle = merge(style, props.styles);  
        setStyles(extendedStyle);        
    }, [isMobile]);

    return (
        <div
            onMouseEnter={()=>{ setHover(true); }}
            onMouseLeave={()=>{ setHover(false); }} 
            style={styles.wrapper}>
            <Button type="button" style={styles.button}>
                <div style={styles.labelWrapper}>
                    {props.children}
                    <Icon style={styles.labelIcon}/>                    
                </div>                
            </Button>             
            <div style={hover ? styles.hover : styles.leave}>                       
                {props.items.map((item, index) =>
                    <div key={index}>{item.item}</div>
                )}
            </div>             
        </div>
    );
}