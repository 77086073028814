import { Color } from "../enums/Color";

export const profileEntrance = {
    mobile: {
        dropdownLabel: {   
            textDecoration: "none",            
            fontSize: "16px",
            fontWeight: "bold" as "bold", 
            letterSpacing: "0px",
            color: Color.Black,
            cursor: "pointer"
        }, 
        dropdownItem: {
            hover: {
                backgroundColor: Color.Green,                
                padding: "8px 0",
                borderRadius: "8px",
                borderBottom : `1px ${Color.White} solid`,
                color : Color.White
            },
            leave: {
                backgroundColor: Color.White,
                padding: "8px 0",                
                borderBottom : `1px ${Color.MiddleAsh} solid`
            }
        },
        wrapper: {    
            display: "flex",           
            justifyContent: "center",
            textAlign: "center",
            marginTop: "40px"
        },
        userDropdown: {                        
            wrapper: {
                width: "auto",                
                display: "flex",
                padding: "revert",
                justifyContent: "center",
                paddingBottom: "20px"
            },        
            button: {
                padding: "revert"
            },     
            labelWrapper: {                
                alignItems: "center"
            },
            labelIcon: {
                width: "16px",
                height: "16px",
                fill: Color.LightAsh,                
                marginLeft: "10px"                
            },            
            hover: {              
                width: "160px",                
                top: "72px",
                right: "unset"                
            }
        },
        userAvatar: {
            height: "50px",
            width: "50px",
            border: "8px solid " + Color.Green,
            borderRadius: "50%",                        
            cursor: "pointer",
            objectFit: "cover"          
        },
        buttonWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        loginButton: {            
            width: "200px",
            height: "42px",            
            fontSize: "16px",
            fontWeight: "bold",
            backgroundColor: Color.Green,
            color: Color.White,
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            margin: "5px 0"
        },
        teacherLogin: {
            backgroundColor: Color.HarvestGold
        }
    },
    desktop: {
        wrapper: {            
            marginRight: "50px",
            marginTop: "unset"
        },
        columnWrapper: {
            marginRight: 0
        },
        buttonWrapper: {
            flexDirection: "row"
        },  
        columnButtons: {
            flexDirection: "column"
        },
        loginButton: {
            width: "10.42vw",
            height: "2.19vw",            
            fontSize: "0.83vw",
            borderRadius: "0.52vw",
            margin: "5px 5px"   
        }
    }
}