import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getPageLocalizations } from "../../services/CommonService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Localization } from "../../models/Localization";
import { ToolBar } from "../../components/Shared/ToolBar";
import { getGradeCategorySubjects } from "../../services/TeacherService";
import { CardListLoader } from "../../loaders/CardListLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { NoData } from "../../components/Shared/NoData";
import { TeacherSearchResponseModel } from "../../models/TeacherSearchResponseModel";
import { Pagination } from "../../components/Shared/Pagination";
import { MenuOption } from "../../models/MenuOption";
import { Option } from "../../models/Option";
import { TeacherSearchRequestModel } from "../../models/TeacherSearchRequestModel";
import { SingleSelectMenuInput } from "../../components/Shared/Inputs/SingleSelectMenuInput";
import { InputLoader } from "../../loaders/InputLoader";
import { SingleSelectInput } from "../../components/Shared/Inputs/SingleSelectInput";
import { ReactComponent as DropdownIcon } from "../../assets/svgs/drop_down.svg";
import { getSeoName } from "../../helpers/Common";
import { OnlineClass } from "../../models/OnlineClass";
import { getSearchRequestModel, postSearchResponseModel } from "../../services/ClassService";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedTeacher } from "../../models/LoggedTeacher";
import { Button } from "../../components/Shared/Button";
import { LoggedStudent } from "../../models/LoggedStudent";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { OnlineTexts as Texts } from "../../helpers/LayoutTexts";
import { Helmet } from "react-helmet-async";

export const ClassListPage: React.FC = () => {    
    const [searchParams] = useSearchParams();  
    const navigate = useNavigate();    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassPage")); 
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();  
    const student: LoggedStudent | null = authContext.getLoggedStudent(); 
    const [loading, setLoading] = useState<boolean>(true);    
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [isFilterLoad, setIsFilterLoad] = useState<boolean>(false);
    const [isFilterExpand, setIsFilterExpand] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);      
    const [categoryGrades, setCategoryGrades] = useState<MenuOption[]>([]);                 
    const [subjects, setSubjects] = useState<Option[]>([]);        
    const [mediums, setMediums] = useState<Option[]>([]);                                            
    const [classTypes, setClassTypes] = useState<Option[]>([]);
    const [onlineClasses, setOnlineClasses] = useState<OnlineClass[]>([]);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });        
    const gradeCategoryId: number = searchParams.get("gradeCategoryId") === null ? -1 : +searchParams.get("gradeCategoryId")!;
    const gradeId: number = searchParams.get("gradeId") === null ? -1 : +searchParams.get("gradeId")!;
    const subjectId: number = searchParams.get("subjectId") === null ? -1 : +searchParams.get("subjectId")!;
    const mediumId: number = searchParams.get("mediumId") === null ? -1 : +searchParams.get("mediumId")!;
    const classTypeId: number = searchParams.get("classTypeId") === null ? -1 : +searchParams.get("classTypeId")!;        
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>();
    const [totalClassCount, setTotalClassCount] = useState<number>();    
    const pageSize: number = 20;
    const subjectCharacterLength: number = isMobile ? 42 : 84;    
    const gradeCharacterLength: number = isMobile ? 42 : 84;    
    const [countDisplay, setCountDisplay] = useState<boolean>(false);
    const scrollElementRef = useRef<any>();
    const bannerType = isMobile ? 7 : 8;

    useEffect(() => {    
        getLocalizations();          
        getRequestModelData();  
        expandFilterPanel();            
    }, []);

    useEffect(() => {                    
        getOnlineClassList();                     
    }, [page, gradeId, subjectId, mediumId, classTypeId]);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("onlineClassPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 36)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getOnlineClassList() {          
        let responseModel: TeacherSearchResponseModel = {
            PageNo: page,
            RecordsPerPage: pageSize,
            GradeCategoryId: gradeCategoryId,
            GradeId: gradeId,
            SubjectId: subjectId,
            MediumId: mediumId,
            ClassTypeId: classTypeId                                    
        }
        await postSearchResponseModel(responseModel)
        .then((onlineClassList: OnlineClass[]) => {
            setOnlineClasses(onlineClassList);               
            if(onlineClassList.length > 0) { 
                let totalPageCount = Math.ceil(onlineClassList[0].RecordCount! / pageSize);            
                setTotalPages(totalPageCount);
                setTotalClassCount(onlineClassList[0].RecordCount); 
                enableCountDisplay();
            }    
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }
    
    async function getRequestModelData() {
        let intialGradeCategoryId = gradeCategoryId === -1 ? "" : gradeCategoryId.toString();              
        await getSearchRequestModel(intialGradeCategoryId)
        .then((requestModel: TeacherSearchRequestModel) => {            
            setCategoryGrades(requestModel.CategoryGrades);
            setSubjects(requestModel.Subjects);
            setMediums(requestModel.Mediums);
            setClassTypes(requestModel.ClassTypes);                                  
            setIsFilterLoad(true);            
        })
        .catch((error) => {
            // toErrorPage(error);
        });                        
    }

    async function getGradeCategorySubjectList(gradeCategoryId: string) {                
        await getGradeCategorySubjects(gradeCategoryId)
        .then((subjectList: Option[]) => {            
            setSubjects(subjectList);
            setSubjectLoading(false);           
        })
        .catch((error) => {
            // toErrorPage(error);
        });                            
    }

    const setSelectedGrade = (gradeCategoryId: number, selectedGradeId: number) => {  
        setSubjectLoading(true);
        if(gradeCategoryId !== -1) {                        
            getGradeCategorySubjectList(gradeCategoryId.toString());
        }
        else {
            getGradeCategorySubjectList("");
        }      
        searchParams.set("gradeCategoryId", gradeCategoryId.toString());
        searchParams.set("gradeId", selectedGradeId.toString());
        searchParams.delete("subjectId");
        handleNavigate();
    }

    const setSelectedSubject = (selectedSubjectId: number) => {        
        searchParams.set("subjectId", selectedSubjectId.toString());
        handleNavigate();
    }

    const setSelectedMedium = (selectedMediumId: number) => {        
        searchParams.set("mediumId", selectedMediumId.toString());
        handleNavigate();
    }

    const setSelectedClassType = (selectedClassTypeId: number) => {        
        searchParams.set("classTypeId", selectedClassTypeId.toString());
        handleNavigate();
    }

    const handleNavigate = () => {                                    
        const newUrl = "/online-classes/" + getPageSEO() + `?${searchParams}`;
        navigate(newUrl);                
    }

    const handlePagination = (pageNumber: number) => {
        searchParams.set("page", pageNumber.toString()); 
        scrollElementRef.current.scrollIntoView();
        handleNavigate();                                    
    }

    const enableCountDisplay = () => {
        if(gradeId === -1 && subjectId === -1 && mediumId === -1 && classTypeId === -1) {
            setCountDisplay(false);
        } 
        else {
            setCountDisplay(true);
        }
    }

    const expandFilterPanel = () => {
        if(gradeId !== -1 || subjectId !== -1 || mediumId !== -1 || classTypeId !== -1) {
            setIsFilterExpand(true);
        }
    }

    const toClassView = (onlineClass: OnlineClass) => {
        let pageSEO = `${onlineClass.TeacherName} ${onlineClass.Subjects} online class`;
        pageSEO = pageSEO.trim();
        pageSEO = getSeoName(pageSEO);
        navigate("/online-class/description/" + onlineClass.OnlineClassId + "/" + pageSEO);
    }

    const toMyClasses = () => {
        if(teacher !== null) {
            navigate("/teacher/online-classes");
        }
        if(student !== null) {
            navigate("/student/online-classes");
        }
    }

    const getPageSEO = (): string => {        
        let gradeSEO = "", subjectSEO = "", mediumSEO = "";                
        if(searchParams.get("gradeId") !== null && searchParams.get("gradeId") !== '-1') {     
            let selectedGradeCategoryId = +searchParams.get("gradeCategoryId")!;
            let selectedGradeId = +searchParams.get("gradeId")!;       
            gradeSEO = categoryGrades.find(x => x.LabelId === selectedGradeCategoryId)!.Options.find(y => y.Value === selectedGradeId)!.SecondaryOption!;
        }
        if(searchParams.get("subjectId") !== null && searchParams.get("subjectId") !== '-1') {                 
            let selectedSubjectId = +searchParams.get("subjectId")!;                
            subjectSEO = subjects.find(x => x.Value === selectedSubjectId)!.SecondaryOption!;
        }
        if(searchParams.get("mediumId") !== null && searchParams.get("mediumId") !== '-1') {                 
            let selectedMediumId = +searchParams.get("mediumId")!;                
            mediumSEO = mediums.find(x => x.Value === selectedMediumId)!.SecondaryOption!;
        }                
        let pageSEO = `${gradeSEO} ${subjectSEO} ${mediumSEO} online tutors tuition classes`;
        pageSEO = pageSEO.trim();
        return getSeoName(pageSEO);
    }

    return (
        <>
            <Helmet>
                <title>{Texts.title}</title>
                <meta name="description" content={Texts.description}/>                    
            </Helmet>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
                    {(teacher || student) && <Button style={styles.classButton} onClick={toMyClasses}>{"My Classes"}</Button>}
                    <div style={{...styles.filter, ...(isFilterExpand && styles.filter.expand)}}>
                        <div style={{...styles.filterExpandButton, ...(isFilterExpand && styles.filterExpandButton.expand)}} onClick={() => setIsFilterExpand((prev) => !prev)}>
                            {"Search Filters"}
                            <DropdownIcon style={{...styles.expandButtonIcon, ...(isFilterExpand && styles.expandButtonIcon.up)}}/>
                        </div>
                        {(isFilterLoad && isFilterExpand) && <div style={styles.filterContent}>
                            <SingleSelectMenuInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwGradeLabel')?.ElementValue!}
                                menuOptions={categoryGrades}                    
                                selectedOptionId={gradeId}
                                setSelectedOption={setSelectedGrade} 
                                searchEnabled={true}
                            />
                            {subjectLoading ? <InputLoader /> :
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwSubjectLabel')?.ElementValue!}
                                options={subjects} 
                                selectedId={subjectId}
                                setSelected={setSelectedSubject}
                                searchEnabled={true}
                            />}
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwMediumLabel')?.ElementValue!}                        
                                options={mediums} 
                                selectedId={mediumId}
                                setSelected={setSelectedMedium}
                            />
                            <SingleSelectInput 
                                label={localizations.find(({ ElementName }) => ElementName === 'vwClassTypeLabel')?.ElementValue!}                        
                                options={classTypes} 
                                selectedId={classTypeId}
                                setSelected={setSelectedClassType}
                            />                                                           
                        </div>}
                    </div>                        
                    <div style={styles.class.container} ref={scrollElementRef}>
                        {loading ? <CardListLoader/> : <>
                            {onlineClasses.length === 0 ? <NoData/> : <>
                                {countDisplay && <div style={styles.listCount}>{totalClassCount + " Online Classes"}</div>}                        
                                {onlineClasses.map((onlineClass, index) =>
                                    <div key={index} style={styles.class.card} onClick={() => toClassView(onlineClass)}>                                
                                        <div style={styles.class.imageWrapper}>
                                            <img src={onlineClass.ClassImageUrl ? onlineClass.ClassImageUrl : Texts.defaultOnlineAd} alt="ad image" style={styles.class.image}></img>
                                        </div>
                                        <div style={styles.class.detailWrapper}>
                                            <div style={styles.class.name}>{onlineClass.TeacherName}</div>
                                            {onlineClass.Subjects && <div style={styles.class.subjects}>
                                                {onlineClass.Subjects.length > subjectCharacterLength ? onlineClass.Subjects.slice(0, subjectCharacterLength) + " ..." : onlineClass.Subjects}
                                            </div>}  
                                            {onlineClass.Grades && <div style={styles.class.grades}>
                                                {onlineClass.Grades.length > gradeCharacterLength ? onlineClass.Grades.slice(0, gradeCharacterLength) + " ..." : onlineClass.Grades}
                                            </div>}                                  
                                            <div style={styles.class.see}>{"See More..."}</div>                                
                                        </div>
                                    </div>
                                )}
                                {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination} />}
                            </>}
                        </>}
                    </div> 
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
        </>
    )
}