import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FooterTexts as Texts } from '../../helpers/LayoutTexts';
import { StyleContext } from '../../providers/StyleContextProvider';
import { Title } from './Title';
import { ReactComponent as Location } from '../../assets/svgs/location.svg';
import { ReactComponent as Phone } from '../../assets/svgs/phone.svg';
import { ReactComponent as Mail } from '../../assets/svgs/mail.svg';
import { ReactComponent as Whatsapp } from '../../assets/svgs/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svgs/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/svgs/youtube.svg';
import { ReactComponent as Tiktok } from '../../assets/svgs/tiktok.svg';
import { FooterMenu } from './FooterMenu';

export const Footer: React.FC = () => {   
  const styleContext = useContext(StyleContext);
  const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("footer"));                
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  
  useEffect(() => { 
    setStyles(styleContext.getComponentStyle("footer"));
  }, [isMobile]);

  return (
    <div style={styles}>
      <Title styles={styles.title} />
      <div style={styles.details}>
        <div style={styles.wrapper}>
          <Location style={styles.smallIcon}/>
          <div>{Texts.address}</div>
        </div>
        <div style={styles.wrapper}>
          <Mail style={styles.smallIcon}/>
          <div>{Texts.email}</div>
        </div>
        <div style={styles.wrapper}>
          <Phone style={styles.smallIcon}/>
          <div>{Texts.telephone}</div>
        </div>
      </div>
      <FooterMenu styles={styles.menu}/>    
      <div style={styles.copyright}>
        <div style={styles.socialIconWrapper}>
          <a href="https://wa.me/94719464357" target="_blank">
            <Whatsapp style={styles.largeIcon}/>
          </a>  
          <a href="https://www.facebook.com/people/tuteclasscom/61551029065996/" target="_blank">
            <Facebook style={styles.largeIcon}/>
          </a>          
          <a href="https://www.instagram.com/tuteclass.com_/" target="_blank">
            <Instagram style={styles.largeIcon}/>
          </a>
          <a href="https://www.youtube.com/@tuteclass" target="_blank">
            <Youtube style={styles.largeIcon}/>
          </a>
          <a href="https://www.tiktok.com/@tuteclass.com" target="_blank">
            <Tiktok style={styles.largeIcon}/>
          </a>          
        </div>
        {Texts.copyright}
      </div>
    </div>
  );
}