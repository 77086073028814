import React, { useContext, useEffect, useState } from 'react';
import { Button } from './Button';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../assets/svgs/back.svg";
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { ReactComponent as MobileMenu } from '../../assets/svgs/mobile_menu.svg';

interface Props {            
    children?: React.ReactNode;    
};

export const ToolBar: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("toolBar")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false);    
    
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("toolBar"));                                                                   
    }, [isMobile]);

    const handleBackButtonClick = () => {
        navigate(-1);
    }
    
    const handleMobileMenuToggle = () => {        
        setMobileMenuToggle((prev) => !prev);
    }

    return (
        <>
            <MobileMenuDrawer open={mobileMenuToggle} toggle={handleMobileMenuToggle}/>
            <div style={styles.wrapper}>
                <Button type="button" style={styles.backButton} onClick={handleBackButtonClick}>
                    <Back style={styles.backIcon}/>
                </Button>
                {props.children}
                <Button type="button" style={styles.mobileMenuButton} onClick={handleMobileMenuToggle}><MobileMenu/></Button>
            </div>
        </>
    );
}