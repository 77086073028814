import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToolBar } from "../../components/Shared/ToolBar";
import { CardListLoader } from "../../loaders/CardListLoader";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { NoData } from "../../components/Shared/NoData";
import { AuthContext } from "../../providers/AuthContextProvider";
import { Button } from "../../components/Shared/Button";
import { ReactComponent as EditIcon } from "../../assets/svgs/edit.svg";
import { ReactComponent as BinIcon } from "../../assets/svgs/bin.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/svgs/clock.svg";
import { ReactComponent as EyeIcon } from "../../assets/svgs/eye-open.svg";
import { ReactComponent as PlusIcon } from "../../assets/svgs/plus.svg";
import { ReactComponent as Whatsapp } from "../../assets/svgs/whatsapp.svg";
import { ReactComponent as FileUpload } from "../../assets/svgs/file-upload.svg";
import { ReactComponent as CloseCircle } from "../../assets/svgs/close-circle-ash.svg";
import { ReactComponent as Videos } from "../../assets/svgs/videos.svg";
import { OnlineClassWeek } from "../../models/OnlineClassWeek";
import { deleteOnlineClassWeek, getClassWeeksByTeacher, getRecordingUploadURL, getWeekAttendStudents, getWeekPaidStudents, updateRecordingLink, uploadZoomRecordingVideo } from "../../services/ClassService";
import { getClassWeekPaymentSlipUrl, getFormatedDate, getFormatedTime, getHost, validateVideo } from "../../helpers/Common";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { TeacherTexts as Texts } from "../../helpers/LayoutTexts";
import { useAlert } from "../../hooks/useAlert";
import { PopUpDialog } from "../../components/Shared/Dialogs/PopUpDialog";
import { LoggedTeacher } from "../../models/LoggedTeacher";
import PDFViewer from "../../components/Shared/PDFViewer";
import { Student } from "../../models/Student";
import useErrorPage from "../../hooks/useErrorPage";
import { UserType } from "../../enums/UserType";
import VideoUploadLoader from "../../loaders/VideoUploadLoader";

const monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];

export const TeacherClassWeekPage: React.FC = () => {   
    const params = useParams(); 
    const location = useLocation();
    const navigate = useNavigate();
    const classId: number = +params.classId!;       
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassPage")); 
    const authContext = useContext(AuthContext);
    const teacher: LoggedTeacher | null = authContext.getLoggedTeacher();
    const [classWeeks, setClassWeeks] = useState<OnlineClassWeek[]>([]);    
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>();
    const [viewMoreIndex, setViewMoreIndex] = useState<number>(-1);
    const {Alert, openAlert} = useAlert();             
    const [toErrorPage] = useErrorPage();                          
    const bannerType = isMobile ? 7 : 8; 
    const baseURL = getHost();
    const [videoUploadWeek, setVideoUploadWeek] = useState<OnlineClassWeek>();
    const [openVideoDialog, setOpenVideoDialog] = useState<boolean>(false);
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const [videoFile, setVideoFile] = useState<File>();
    const [videoFileUrl, setVideoFileUrl] = useState<string>();
    const [videoLoading, setVideoLoading] = useState<boolean>(false);
    
    useEffect(() => {    
        if(teacher !== null) {
            getWeekList();                               
        }
        else {
            const searchParams = { callbackUrl: location.pathname + location.search, message: "Please login or register to access the class week page." };
            navigate({
                pathname: '/teacher/login',
                search: `?${createSearchParams(searchParams)}`
            });
        }
    }, []);
        
    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("onlineClassPage"));        
    }, [isMobile]);

    async function getWeekList() {                  
        await getClassWeeksByTeacher(classId)
        .then((classWeekList: OnlineClassWeek[]) => {
            setClassWeeks(classWeekList);               
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    async function deleteClassWeek(weekId: number) {                  
        await deleteOnlineClassWeek(weekId)
        .then((success: boolean) => {  
            setOpenDialog(false);
            if(success) {
                openAlert("success", "Online class week has been deleted successfully.");  
                getWeekList();                                                       
            }
            else {
                openAlert("error", "Oops! something went wrong.");
            }
        })
        .catch((error) => {            
            //toErrorPage(error);
        });               
    }

    const toggleViewMore = (index: number) => {
        if(index === viewMoreIndex) {
            setViewMoreIndex(-1);
        }
        else {
            setViewMoreIndex(index);
        }
    }

    const toManageClassWeek = (weekId: number) => {
        navigate(`/teacher/week-manage/${classId}/${weekId}`);
    }

    const handleWhatsappShare = (event: any, week: OnlineClassWeek) => {
        event.stopPropagation();
        const url = baseURL + "student/class-weeks/" + classId + "?weekId=" + week.OnlineClassWeekId;
        const message = getMonthWeek(week.Date!) + "%0A" + week.Title + "%0A" + getFormatedDate(week.Date!, true) + ", " + getFormatedTime(week.Time!) + "%0AURL: " + url;
        var whatsappUrl = (isMobile ? "whatsapp://" : "https://web.whatsapp.com/") + "send?text=" + message;
        window.open(whatsappUrl, '_blank');
    }

    const getMonthWeek = (dateStr: string): string => {
        const date = new Date(dateStr);
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const weekNumber = Math.ceil(day / 7);
        return `${month} Week ${weekNumber}`;
    }

    const openDeleteWarning = (weekId: number, title?: string) => {
        let content = <>
            <h1 style={styles.dialog.title}>{"Are you sure?"}</h1>
            <p style={styles.dialog.message}>{`${title} class week will be deleted permanently.`}</p>
            <div className="center-content" style={styles.dialog.buttons}>
                <Button style={{...styles.dialog.button, ...styles.dialog.button.blue}} onClick={closeDialog}>{"NO"}</Button>                
                <Button style={{...styles.dialog.button, ...styles.dialog.button.red}} onClick={() => deleteClassWeek(weekId)}>{"YES"}</Button>
            </div>
        </>        
        setDialogContent(content);        
        setOpenDialog(true);
    }
          
    async function getPaidStudentList(weekId: number) {
        await getWeekPaidStudents(weekId, teacher!.TeacherId, teacher!.Token)
        .then((students: Student[]) => {  
            let content = <>
                <div style={styles.studentList.title}>{"Paid Students"}</div>
                {students.length === 0 ? <NoData/> : <>
                    {students.map((student, index) => <div style={styles.studentList.rowWrapper}> 
                        <div style={styles.studentList.index}>{index + 1}</div>
                        <div style={styles.studentList.content}>
                            <div style={styles.studentList.name}>{student.FirstName + " " + student.LastName}</div>
                            <div style={styles.studentList.dateWrapper}>
                                <div>{student.PaidAmount + " LKR"}</div>
                                <div>{getFormatedDate(student.PaidDate)}</div>
                            </div>
                        </div>
                    </div>)}
                </>}
            </>        
            setDialogContent(content);        
            setOpenDialog(true);
        })
        .catch((error) => {            
            //toErrorPage(error);
        }); 
    }

    async function getAttendStudentList(weekId: number) {
        await getWeekAttendStudents(weekId, teacher!.TeacherId, teacher!.Token)
        .then((students: Student[]) => {  
            let content = <>
                <div style={styles.studentList.title}>{"Attend Students"}</div>
                {students.length === 0 ? <NoData/> : <>
                    {students.map((student, index) => <div style={styles.studentList.rowWrapper}> 
                        <div style={styles.studentList.index}>{index + 1}</div>
                        <div style={styles.studentList.content}>                   
                            <div style={styles.studentList.name}>{student.FirstName + " " + student.LastName}</div>
                            <div style={styles.studentList.dateWrapper}>
                                <div>{getFormatedDate(student.AttendInDate)}</div>
                            </div>
                        </div>
                    </div>)}
                </>}
            </>        
            setDialogContent(content);        
            setOpenDialog(true);
        })
        .catch((error) => {            
            //toErrorPage(error);
        }); 
    }

    const manageRecordingVideo = (week: OnlineClassWeek) => {
        setVideoUploadWeek(week);
        if(week.ZoomRecordingLink) {
            setVideoFileUrl(week.ZoomRecordingLink);
        }
        setOpenVideoDialog(true);
    }

    const handleVideoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
        let fileUrl: string;
        const uploadedVideo = event.target.files?.[0];        
        if(uploadedVideo) {            
            const videoValidation = validateVideo(uploadedVideo);            
            if(videoValidation.valid) {
                setVideoFile(uploadedVideo);
                fileUrl = URL.createObjectURL(uploadedVideo);                
                setVideoFileUrl(fileUrl);                                                                                                   
            }
            else {
                openAlert("error", videoValidation.invalidMessage!);
            }                                           
        }
        // Revoke the object URL on component unmount
        return () => URL.revokeObjectURL(fileUrl);
    }
    
    const triggerVideoInput = () => {
        if(videoInputRef.current) {
            videoInputRef.current.click();
        }
    };

    const removeSelectedVideoFile = () => {        
        setVideoFile(undefined);
        if(videoUploadWeek!.ZoomRecordingLink) {
            setVideoFileUrl(videoUploadWeek!.ZoomRecordingLink);
        }
        else {
            setVideoFileUrl(undefined);        
        }
    }

    const uploadRecordingVideo = async () => {
        setVideoLoading(true);
        await getRecordingUploadURL(teacher!.TeacherId, teacher!.Token, videoUploadWeek!.OnlineClassWeekId, videoFile!.type)
        .then(async (data: any) => {  
            await uploadZoomRecordingVideo(data.UploadURL, videoFile!)
            .then(() => {
                const recordingLink = `https://d3qj4g5mokt6n6.cloudfront.net/recording-${videoUploadWeek!.OnlineClassWeekId}-${data.Timestamp}.mp4`;
                updateRecordingLink(teacher!.TeacherId, teacher!.Token, videoUploadWeek!.OnlineClassWeekId, data.Timestamp);                
                setClassWeeks((prevWeeks) =>
                    prevWeeks.map((week) =>
                        week.OnlineClassWeekId === videoUploadWeek!.OnlineClassWeekId ? { ...week, ZoomRecordingLink: recordingLink } : week
                    )
                );
                setVideoLoading(false);
                closeVideoDialog();
                openAlert("success", "Video uploaded successfully.");
            })
            .catch((error) => {            
                toErrorPage(error, UserType.Teacher);
            });                       
        })
        .catch((error) => {            
            toErrorPage(error, UserType.Teacher);
        });
    };
    
    const closeDialog = () => {
        setOpenDialog(false);
    }

    const closeVideoDialog = () => {
        if(!videoLoading) {
            setOpenVideoDialog(false);
            setVideoUploadWeek(undefined);
            setVideoFile(undefined);
            setVideoFileUrl(undefined);
        }
    }

    return (
        <>
            <ToolBar>{"Online Class Weeks"}</ToolBar>
            <DesktopMenuDrawer/>
            <div style={styles.container}>
                <div style={styles.leftWrapper}>
                    <h1 style={styles.desktopTitle}>{"Online Class Weeks"}</h1>            
                    <div style={styles.week.container}>
                        {loading ? <CardListLoader/> : <>
                            {classWeeks.length === 0 ? <NoData/> : <>  
                                {classWeeks.length > 4 && <Button style={styles.week.addButtonTop} onClick={() => toManageClassWeek(0)}>
                                    {"Create New Week"}<PlusIcon style={styles.week.addButtonTop.icon} />
                                </Button>}                                             
                                {classWeeks.map((classWeek, index) =>
                                    <div key={index} style={{...styles.week.card, ...((classWeek.IsExpired || classWeek.IsCompleted) && styles.week.expired)}}> 
                                        <div style={styles.week.weekNo}>{getMonthWeek(classWeek.Date!)}</div>                                                                                                  
                                        <div style={styles.week.title}>
                                            {classWeek.Title}
                                        </div>                                   
                                        <div style={styles.week.dateWrapper}>
                                            <div style={styles.week.rowWrapper}>
                                                <CalendarIcon style={styles.week.calendarIcon}/>{getFormatedDate(classWeek.Date!)}
                                            </div>
                                            <div style={styles.week.rowWrapper}>
                                                <ClockIcon style={styles.week.clockIcon}/>{getFormatedTime(classWeek.Time!)}
                                            </div>                                    
                                        </div>
                                        <div style={styles.week.dateWrapper}>
                                            <div style={styles.week.rowWrapper} onClick={() => getPaidStudentList(classWeek.OnlineClassWeekId)}>
                                                <span style={styles.week.studentCount}>{classWeek.PaidStudentCount}</span>
                                                <span>{"Students Paid"}</span>  
                                            </div>
                                            <div style={styles.week.rowWrapper} onClick={() => getAttendStudentList(classWeek.OnlineClassWeekId)}>
                                                <span style={styles.week.studentCount}>{classWeek.AttendStudentCount}</span>
                                                <span>{"Students Attend"}</span>  
                                            </div>                                  
                                        </div>
                                        {!(classWeek.IsExpired || classWeek.IsCompleted) && <Button type="button" style={styles.week.whatsappButton} onClick={(event) => handleWhatsappShare(event, classWeek)}>
                                            <Whatsapp style={styles.week.whatsappIcon} />{" Share week link in WhatsApp"}
                                        </Button>}
                                        {(index === viewMoreIndex) && <>
                                            {classWeek.PaymentRequired ? <>                                            
                                                {(classWeek.IsExpired || classWeek.IsCompleted) ? <div style={styles.week.revenueWrapper}>
                                                    <div style={styles.week.revenueText}>{"Week gross revenue: "}<span style={styles.week.revenueValue}>{classWeek.WeekGrossRevenue + " LKR"}</span></div>
                                                    <div style={styles.week.revenueText}>{"tuteclass service charge (4%): "}<span style={styles.week.revenueValue}>{(classWeek.WeekGrossRevenue! * 0.04) + " LKR"}</span></div>
                                                    <div style={styles.week.revenueText}>{"Week net revenue: "}<span style={styles.week.revenueValue}>{(classWeek.WeekGrossRevenue! - (classWeek.WeekGrossRevenue! * 0.04)) + " LKR"}</span></div>
                                                    {classWeek.IsRevenueTransferred ? <div style={styles.week.transferred}>
                                                        {"Week net revenue has been transferred to your account."}
                                                        <PDFViewer pdfUrl={getClassWeekPaymentSlipUrl(classWeek.OnlineClassWeekId, teacher!.TeacherId, teacher!.Token)} styles={styles.week.pdfViewer} />
                                                    </div> 
                                                    : <div style={styles.week.notTransferred}>
                                                        {"Week net revenue will be transferred to your account soon."}                                                
                                                    </div>}
                                                </div>
                                                : <div style={styles.week.notCompleted}>{"This week has not been completed yet...!"}</div>}
                                            </>
                                            : <div style={styles.week.notCompleted}>{"This is a free week. No any payment information."}</div>}
                                        </>}
                                        <div style={styles.week.buttonWrapper}>
                                            {(classWeek.IsExpired || classWeek.IsCompleted) ? <Button style={styles.week.editButton} onClick={() => manageRecordingVideo(classWeek)}>
                                                {isMobile ? <Videos style={styles.week.editButton.icon} /> : "Recording Video"}
                                            </Button> 
                                            : <Button style={styles.week.editButton} onClick={() => toManageClassWeek(classWeek.OnlineClassWeekId)}>
                                                {isMobile ? <EditIcon style={styles.week.editButton.icon} /> : "Edit"}
                                            </Button>}                                      
                                            {!(classWeek.IsExpired || classWeek.IsCompleted) && <Button style={styles.week.deleteButton} onClick={() => openDeleteWarning(classWeek.OnlineClassWeekId, classWeek.Title)}>
                                                {isMobile ? <BinIcon style={styles.week.deleteButton.icon} /> : "Delete"}
                                            </Button>}
                                            <Button style={styles.week.viewButton} onClick={() => toggleViewMore(index)}>
                                                <EyeIcon style={styles.week.viewButton.icon} />{(index === viewMoreIndex) ? "View Less" : "View More"}
                                            </Button>
                                        </div>                                
                                    </div>
                                )}                        
                            </>}
                            <Button style={styles.week.addButtonBottom} onClick={() => toManageClassWeek(0)}>
                                {"Create New Week"}<PlusIcon style={styles.week.addButtonBottom.icon} />
                            </Button>
                        </>}
                    </div>
                </div>
                <div style={styles.rightWrapper}>
                    {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                    : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                </div>
            </div>
            {openDialog && <PopUpDialog styles={styles.dialog.popUp} toggle={closeDialog} closeIcon={true}>
                {dialogContent}
            </PopUpDialog>} 
            {openVideoDialog && <PopUpDialog styles={styles.videoPopUp} toggle={closeVideoDialog} closeIcon={true}>
                {videoLoading ? <VideoUploadLoader />
                : <>
                    <div style={styles.week.weekNo}>{getMonthWeek(videoUploadWeek!.Date!)}</div>                                                                                                  
                    <div style={styles.week.title}>
                        {videoUploadWeek!.Title}
                    </div>
                    <div>
                        <label style={styles.week.iconLabel}>
                            <span>{"Upload Zoom recording video"}</span>
                            <div>                                                                        
                                {videoFileUrl && <FileUpload style={styles.week.videoUploadIcon} onClick={triggerVideoInput} />}
                                {videoFile && <CloseCircle style={styles.week.videoCloseIcon} onClick={removeSelectedVideoFile} />}
                            </div>
                        </label>
                        {videoFileUrl && <video width={"100%"} controls src={videoFileUrl} />}                            
                        <input
                            type="file"
                            accept="video/*"
                            ref={videoInputRef}
                            onChange={handleVideoFileChange}
                            style={{display: "none"}}
                        />
                        <div style={{...styles.week.uploadFile, ...(videoFileUrl && {display: "none"})}} onClick={triggerVideoInput}>
                            <div style={styles.week.uploadIconWrapper}>
                                <FileUpload style={styles.week.uploadIcon} />
                            </div>
                            <div style={styles.week.uploadText}>
                                {"Add recording video if available."}                                  
                            </div>
                        </div>
                        {videoFile && <Button type="submit" style={styles.week.submitButton} onClick={uploadRecordingVideo}>                    
                            {"Upload Video"}                    
                        </Button>}
                    </div>
                </>}
            </PopUpDialog>}
            <Alert/>            
        </>
    )
}