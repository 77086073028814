const adClientId = "ca-pub-6427110161920769";
const currentYear = new Date().getFullYear();

export const CommonTexts = {
    apiBaseUrl: "https://api.tuteclass.com/api/", 
    // apiBaseUrl: "http://localhost:7542/api/"    
}

export const Keys = {
    pdftron: "demo:1720748980103:7f9fe7090300000000d3425bb18c480f9977fcb391b10ba3934c11ee81"
}

export const HomeTexts = {
    title: "TUTECLASS : Degrees | Diplomas | Tutors | Tuition Classes",
    description: "Find Degrees, Diplomas, Tutors and Tuition Classes. All degrees and diplomas offer by government and private universities. All tutors and tuition classes.",
    adClient: adClientId,
    adSlotOne: "2053183502",
    adSlotTwo: "6088932756"
}

export const HeaderTexts = {    
    heading: "The Wisdom Pathway",    
    desktopText: "tuteclass.com is an application that caters useful information and services for the learn force of Sri Lanka who are looking for good opportunities in higher studies including degrees and diplomas of different streams. It is a virtual platform which allows quick access to the accurate information that you need and guide you in achieving your higher education goals.",
    mobileText: "tuteclass.com is an application that provide valuable resources and services for teachers and students in Sri Lanka. The goal of the tuteclass.com is to bring distributed and not easily accessible information, resources and services to one digital platform and make easily accessible.",
    mobileSearch: "I'm looking for...",
    desktopSearch: "I'm looking for... (Eg: Degree Name, University)",
    defaultAvatar: process.env.PUBLIC_URL + "/images/default_avatar.jpg"    
}

export const FooterTexts = {
    address: "93/1A, Piriwena Road, Boralesgamuwa.",
    email: "info@clipess.com",
    telephone: "+94719464357",    
    copyright: `© ${currentYear} CLIPESS (PRIVATE) LIMITED. ALL RIGHTS RESERVED`
}

export const LoginTexts = {
    loginError: "The username or password is incorrect"
}

export const DegreeTexts = {
    title: "TUTECLASS : Degrees in Sri Lanka",
    description: "Find all degrees offered by government and private universities in Sri Lanka. Find degrees offered by government universities based on A/L streams and subjects.",    
    adClient: adClientId,
    adSlotOne: "8997342712",
    adSlotTwo: "8805771021"    
}

export const DiplomaTexts = {
    title: "TUTECLASS : Diplomas in Sri Lanka",
    description: "Find all diplomas offered by government and private universities in Sri Lanka. Find diplomas offered by government universities based on related category.",        
    adClient: adClientId,
    adSlotOne: "7183382379",
    adSlotTwo: "1579115171" 
}

export const TeacherTexts = {
    title: "TUTECLASS : Tutors and Tuition Classes",
    description: "Find tutors and tuition classes for any subject. Advanced level, ordinary level and primary tutors and tuition classes for all subjects. Find best tutors.",
    defaultTeacherAd: process.env.PUBLIC_URL + "/images/default_ad.jpg",    
    adClient: adClientId,
    adSlotOne: "4013706827",
    adSlotTwo: "3959287387" 
}

export const OnlineTexts = {
    title: "TUTECLASS : Online Tutors and Online Tuition Classes",
    description: "Find online tutors and online tuition classes for any subject. Advanced level, ordinary level and primary online tutors and online tuition classes for all subjects. Find best online tutors.",
    defaultOnlineAd: process.env.PUBLIC_URL + "/images/default_ad.jpg",    
    adClient: adClientId,
    adSlotOne: "9756092202",
    adSlotTwo: "5451921664" 
}

export const FormTexts = {
    submitError : "Some of the required fields are missing."
}

export const Contacts = {
    address: {
        no: "93/1A,",
        road: "Piriwena Road,",
        city: "Boralesgamuwa."
    },
    email: "info@clipess.com",
    telephone: "+94719464357"
}

export const SEOKeywords = {
    governmentDegree: "/government-university-degrees-in-sri-lanka",
    privateDegree: "/private-university-degrees-in-sri-lanka",
    governmentDiploma: "/government-university-diplomas-in-sri-lanka",
    privateDiploma: "/private-university-diplomas-in-sri-lanka",
    teacher: "/tutors-and-tuition-classes-in-sri-lanka",
    onlineClass: "/online-classes-in-sri-lanka"
}

export const ErrorPageTexts = {
    connect: "We couldn’t connect the wires",
    unavailable: "The page you are looking for might have been removed or is temporarily unavailable.",
    copyright: `© ${currentYear} CLIPESS (PRIVATE) LIMITED. ALL RIGHTS RESERVED`    
}
