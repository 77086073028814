import React from 'react';
import { ReactComponent as Logo } from '../assets/svgs/tuteclass-logo.svg';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';
import { Color } from '../enums/Color';

const loaderStyles = {
  mobile: {                
    height: "66vw",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "4.38vw",
    fontWeight: "bold",
    color: Color.PhilippineGray,
    title: {
      marginTop: "2.89vw"
    }
  },
  desktop: {        
    height: "18.45vw",
    fontSize: "1.13vw",
    title: {
      marginTop: "0.94vw"
    }
  }
};

const VideoUploadLoader: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  var styles  = loaderStyles.mobile; 

  if(!isMobile) {           
    let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
    styles = extendStyles;
  }

  return (
    <div style={styles}>
      <Logo className="spinner-logo" />
      <div style={styles.title}>{"Your video is uploading..."}</div>
      <div>{"This will take a few seconds"}</div>
    </div>
  );
};

export default VideoUploadLoader;