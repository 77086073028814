import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StyleContext } from "../../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { getClassDescription } from "../../services/ClassService";
import { OnlineClass } from "../../models/OnlineClass";
import { ToolBar } from "../../components/Shared/ToolBar";
import { DesktopMenuDrawer } from "../../components/Shared/DesktopMenuDrawer";
import { HorizontalBanner } from "../../components/Shared/HorizontalBanner";
import { VerticalBanner } from "../../components/Shared/VerticalBanner";
import { Localization } from "../../models/Localization";
import { getPageLocalizations } from "../../services/CommonService";
import { OnlineTexts as Texts } from "../../helpers/LayoutTexts";
import { Button } from "../../components/Shared/Button";
import { ClassDescriptionLoader } from "../../loaders/ClassDescriptionLoader";
import { Helmet } from "react-helmet-async";

export const ClassDescriptionPage: React.FC = () => {
    let params = useParams();    
    const navigate = useNavigate();
    const classId: number = +params.classId!;
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("onlineClassPage"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [onlineClass, setOnlineClass] = useState<OnlineClass>();
    const [localizations, setLocalizations] = useState<Localization[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const bannerType = isMobile ? 7 : 8;
    
    useEffect(() => {    
        getLocalizations();
        getClassDescriptionData();
    }, []);

    useEffect(() => {    
        setStyles(styleContext.getComponentStyle("onlineClassPage"));        
    }, [isMobile]);

    async function getLocalizations() {        
        await getPageLocalizations(11, 36)
        .then((localizationList: Localization[]) => {
            setLocalizations(localizationList);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    async function getClassDescriptionData() {        
        await getClassDescription(classId)
        .then((classData: OnlineClass) => {
            setOnlineClass(classData);
            setLoading(false);
        })
        .catch((error) => {            
            //toErrorPage(error);
        });                                    
    }

    const attendClass = () => {        
        navigate("/student/class-weeks/" + classId);
    }

    return (
        <>
            <ToolBar>
                {localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}
            </ToolBar>
            <DesktopMenuDrawer/>
            {loading ? <ClassDescriptionLoader/> : 
            <>
                <Helmet>
                    <title>{onlineClass?.TeacherName + " - tuteclass.com"}</title>
                    <meta name="description" content={onlineClass?.TeacherName + " online classes"}/>                    
                </Helmet>
                <div style={{...styles.container, ...isMobile && {padding: "unset"}}}>
                    <div style={styles.leftWrapper}>
                        <h1 style={styles.desktopTitle}>{localizations.find(({ ElementName }) => ElementName === 'vwAppToolBarTitle')?.ElementValue}</h1>
                        <div>
                            <img src={onlineClass?.ClassImageUrl ? onlineClass?.ClassImageUrl : Texts.defaultOnlineAd} style={styles.detail.image}></img>
                        </div> 
                        <div style={styles.detail.container}>
                            <div style={styles.detail.name}>
                                {onlineClass?.TeacherName}
                            </div> 
                            <div style={styles.detail.subjects}>
                                {onlineClass?.Subjects}
                            </div>                                                      
                            <div style={styles.detail.grades}>
                                {onlineClass?.Grades}
                            </div>
                            {onlineClass?.IsMonthlyPayment ? <div style={styles.detail.payment}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwMonthlyLabel')?.ElementValue + ": " + onlineClass?.MonthlyClassFee + " LKR"}
                            </div>
                            : <div style={styles.detail.payment}>
                                {localizations.find(({ ElementName }) => ElementName === 'vwWeeklyLabel')?.ElementValue + ": " + onlineClass?.WeeklyClassFee + " LKR"}
                            </div>}
                            <div style={styles.detail.description}>
                                <div dangerouslySetInnerHTML={{ __html: onlineClass?.Description! }}/>
                            </div> 
                            <Button style={styles.detail.attendButton} onClick={attendClass}>
                                {"Attend"}                        
                            </Button>  
                        </div>                    
                    </div>
                    <div style={styles.rightWrapper}>
                        {isMobile ? <HorizontalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlotOne={Texts.adSlotOne}/>
                        : <VerticalBanner typeId={bannerType} adsenseClient={Texts.adClient} adsenseSlot={Texts.adSlotTwo}/>}                
                    </div>
                </div>
            </>}
        </>
    )
}